import { enrollStatusObj, recordStatus, enrollmentTypes, instituteNoScheduleObj, batchStatusObj, memberOfObj, PROGRAM_OPTION_OBJ } from './../dummy/stauts';
import { DataService } from './../services/data.service';
import { observable, action } from "mobx";
import { Injectable } from "@angular/core";
import { Router } from '@angular/router';
import * as firebase from 'firebase/app';
import { ConvertService, getDayName, toMonthKey } from 'src/app/shared/services/convert.service';
import { IEnrollment, IImportStudentToClass } from '../interfaces/enrollment';
import { ISession } from '../interfaces/shift';
import { IAttendanceConfirmationLog, IBatch } from '../interfaces/institute';
import { ICourseSchedule } from '../interfaces/schedule';
import { academicTrainingFeeObj, academicTrainingProgramObj, academicYearObj, attendancePolicyObj, campusObj, checkActiveProgram, getCurrentMonthName, groupBy, groupByData, MappingService, numberToDate, orderBy, pushToArray, pushToObject, attendantRemarkObj, sessionObj, shiftObj, toDateKey, toPageKey, userObj, instructorObj, admissionObj, } from 'src/app/shared/services/mapping.service';
import { IMonthlyGrade } from '../interfaces/registrar';
import * as moment from 'moment';
import { ATTENDANCE_CONFIRMATION_STATISTICS, CONFIRM_ATT, CREATE_TIME_TYPE, HR_USER_TYPES, SCHOOL } from '../dummy/config';
import { Subscription } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { IStudentAttendanceMovement, IStudentTimeAttendance } from '../interfaces/student';

@Injectable({ providedIn: 'root' })
export class Schedule {

  @observable public data = [];
  @observable public confirmScheduleData = [];
  @observable public selectedScheduleSessionItems = [];
  @observable public confirmedAttendancesData = [];
  @observable public attendanceConfirmationLogs = [];

  @observable public loading = false;
  @observable public empty = null;
  @observable public process = false;
  @observable public selectedCampus = null;
  @observable public selectedShift = null;
  @observable public selectedProgram = null;
  @observable public selectedBatch = null;

  @observable public selectedLevel = null;
  @observable public selectedSession = null;
  @observable public selectedInstitute = null;
  @observable public selectedTerm = null;
  @observable public selectedAcademicYear = null;

  @observable public configData = null;
  @observable public institutes = [];
  @observable public campus = [];
  @observable public shifts = [];
  @observable public programs = [];
  @observable public level = [];
  @observable public batches = [];
  @observable public groupBatches = [];
  @observable public students = [];
  @observable public batchMembers = [];
  @observable public sessions = [];
  @observable public comingStudent = [];
  @observable public academicYears = [];
  @observable public studentAdmission = [];

  @observable public sessionGroupItems: any = [];
  @observable public sessionItems: any = [];
  @observable public instructorItems: any = [];
  @observable public teacherClassItems: any = [];
  @observable public gradeSheetStudents: any = [];
  @observable public selectedGradeSheet: any = null;
  @observable public selectedSchedule: any = null;
  @observable public attedenceStudents = [];
  @observable public isConfirmSession1: boolean = false;
  @observable public isConfirmSession2: boolean = false;
  storeKey: any = SCHOOL.key;
  schoolConfig = SCHOOL;

  constructor(
    public ds: DataService,
    private router: Router,
    private afs: AngularFirestore
  ) {

  }
  @action
  async fetchBatchAttendenceDetail(studentKey: string, batchKey: string, date: any) {
    const dateKey = toDateKey(date);
    const data = pushToArray(await this.ds.collection('students').doc(studentKey).collection('student_attendance_movement', ref => ref.where('batchKey', '==', batchKey).where('checkIn_date_key', '==', dateKey)).get().toPromise());
    this.attedenceStudents = data;
  }

  @observable processApprove: boolean = false;
  @action
  async addGradeSheet(item: any, callback) {
    this.processApprove = true;
    const batch = this.ds.batch();
    const transcriptRef = this.ds.termFireRef().doc(item.termKey).collection('schedules').doc(item.scheduleKey).collection("grade_sheets").doc(item.key)
    const movementRef = this.ds.gradeSheetsFireRef().doc(item.key)
    const approvedRef = this.ds.gradeSheetsApprovedFireRef().doc(item.key)

    batch.set(approvedRef, item, { merge: true })
    batch.set(movementRef, item, { merge: true })
    batch.set(transcriptRef, item, { merge: true })

    batch.commit().then(() => {
      callback(true, null);
    }).catch((error) => {
      callback(false, error)
    }).finally(() => {
      this.processApprove = false;
    })
  }

  @action
  async addGradeSheetPoint(studentGradeSheet, point, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const { termKey, scheduleKey, key, gradeSheet, gradeSheetKey } = studentGradeSheet;
    const transcriptRef = this.ds.termFireRef().doc(termKey).collection('schedules').doc(scheduleKey).collection("grade_sheet_students").doc(key)
    studentGradeSheet.point = point;
    studentGradeSheet.result = point;
    const item = {
      ...studentGradeSheet,
      docKey: `${gradeSheet.monthKey}_${key}`
    }
    batch.set(this.ds.gradeSheetsFireRef().doc(gradeSheetKey).collection('grade_sheet_students').doc(key), item, { merge: true })
    batch.set(transcriptRef, studentGradeSheet, { merge: true })
    batch.set(this.ds.termFireRef().doc(termKey).collection('grade_sheet_student_subjects').doc(`${gradeSheet.monthKey}_${key}`), item, { merge: true })
    batch.commit().then(() => {
      callback(true, null);
    }).catch((error) => {
      callback(false, error)
    }).finally(() => {
      this.process = false;
    })
  }


  @action
  async fetchSelectGradeSheetStudents(scheduleKey, gradeKey) {
    const docData = await this.ds.gradeSheetsRef(null).doc(gradeKey)
      .collection('grade_sheet_students', ref => ref.where('scheduleKey', '==', scheduleKey))
      .get().toPromise();
    const doc = pushToArray(docData)
    this.selectedGradeSheet = pushToObject(await this.ds.gradeSheetsRef(null).doc(gradeKey).get().toPromise());
    this.gradeSheetStudents = doc;
    return doc;
  }

  @action
  async fetchStudentAdmission() {
    this.process = true;
    const list = await this.ds.studentNewAdmissionRef(this.selectedAcademicYear.key, this.selectedCampus.key, this.selectedLevel.key, null, null).get().toPromise();
    this.studentAdmission = MappingService.pushToArray(list);
    this.studentAdmission = this.studentAdmission.filter(m => m.isPaid)
    this.process = false;
  }

  @action
  searchStudentAdmission(field: string, value) {
    if (value) {
      return this.ds.studentNewAdmissionRef(this.selectedAcademicYear.key, this.selectedCampus.key, this.selectedLevel.key, field, value).valueChanges()
    }
    return this.ds.studentNewAdmissionRef(this.selectedAcademicYear.key, this.selectedCampus.key, this.selectedLevel.key, null, null).valueChanges()
  }

  @action
  async fetchLevelToArray(levelKey, callback) {
    this.process = true;
    const levelDoc = await this.ds.instituteTrainingLevelRef().doc(levelKey).get().toPromise();
    this.process = false;
    callback(MappingService.pushToObject(levelDoc))
  }

  @action
  async fetchInstitutes(institute) {
    this.loading = true;
    const instituteDoc = await this.ds.instituteRef().get().toPromise();
    this.institutes = MappingService.pushToArray(instituteDoc);
    this.selectedInstitute = this.institutes[0];
    if (institute && institute.type === "JAPAN") {
      this.institutes = this.institutes.filter(m => m.key === institute.key);
      this.selectedInstitute = this.institutes[0];
    }
    this.router.navigate([`/app/class-schedule/${this.selectedInstitute.key}`])
    this.loading = false;
  }


  @action
  async fetchInstitutesInStudent() {
    this.loading = true;
    const instituteDoc = await this.ds.instituteRef().get().toPromise();
    this.institutes = MappingService.pushToArray(instituteDoc);
    this.selectedInstitute = this.institutes[0];
    this.router.navigate([`/app/news-students/${this.selectedInstitute.key}`])
    this.loading = false;
  }

  @action
  async resolveBatchSchedule() {
    const ref = this.ds.batchLevelFireRef()
    const batch = this.ds.batch();
    const docs = await this.ds.batchLevelRef().get().toPromise();
    let i = 0;
    const items = pushToArray(docs)//.filter((r: any) => r.key === '04qvJp5lAED9LRNcgiKW');
    items.forEach((m: any) => {
      this.ds.batchLevelFireRef().doc(m.key).update({
        resolve: true
      }).then(() => {
      })
    })
  }
  @action
  async resolveSchedule() {
    const ref = this.ds.academicYearFireRef().doc('gH0GByDZ35OfzIKzhahk').collection('schedules')
    const batch = this.ds.batch();
    const docs = await this.ds.batchLevelRef().get().toPromise();
    let i = 0;
    // docs.forEach(m => {
    //   const doc = m.data();
    //   const sessionItems: any[] = []
    //   const { session, session2, session3, session4, session5, session6 } = doc;
    //   if (session) sessionItems.push(session)
    //   if (session2) sessionItems.push(session2)
    //   if (session3) sessionItems.push(session3)
    //   if (session4) sessionItems.push(session4)
    //   if (session5) sessionItems.push(session5)
    //   if (session6) sessionItems.push(session6)
    //   this.ds.academicYearFireRef().doc('gH0GByDZ35OfzIKzhahk').collection('schedules').doc(m.id).update({
    //     sessionItems: sessionItems
    //   }).then(()=>{
    //   })
    //   // batch.update(ref.doc(m.id), {
    //   //   sessionItems: sessionItems
    //   // })

    // })
    // batch.commit().then(() => {
    // })
  }

  @action
  async fetchProgramRoute(programKey, campusKey, routeName, type?: any) {
    const programDoc = await this.ds.schoolProgramRef().doc(programKey).get().toPromise();
    if (!programDoc.exists) {
      return this.router.navigate([`/`]);
    }
    const selectedProgram = pushToObject(programDoc);
    const { programOption } = selectedProgram;
    const configDoc = await this.ds.academicFirebaseRef().get().toPromise();
    this.configData = MappingService.pushToObject(configDoc);
    const shiftDoc = await this.ds.shiftFirstRef().get().toPromise()

    this.shifts = MappingService.pushToArray(shiftDoc);
    if (!campusKey) {
      const campusDoc = await this.ds.campusFirstRef().get().toPromise()
      this.campus = MappingService.pushToArray(campusDoc);
      campusKey = this.campus[0].key;
    }
    const { term, year } = this.configData;
    this.selectedTerm = programOption && programOption?.key === 1 ? year : term;
    if (type) {
      this.router.navigate([`/registrar/${routeName}/${programKey}/${type}/${getCurrentMonthName()}/batches`],
        {
          queryParams: {
            termKey: this.selectedTerm.key,
            campusKey: campusKey,
            shiftKey: this.shifts[1].key,
          }
        })
    }
    else {
      this.router.navigate([`/registrar/${routeName}/${programKey}/batches`],
        {
          queryParams: {
            termKey: this.selectedTerm.key,
            campusKey: campusKey,
            shiftKey: this.shifts[1].key,
          }
        })
    }
  }

  @observable fetchShiftsRef: any = null;
  @action
  fetchShifts(shiftKey, callback?: any) {
    this.fetchShiftsRef = this.ds.shiftRef()
      .valueChanges()
      .subscribe((docs: any[]) => {
        this.shifts = docs;
        this.selectedShift = docs.find(m => m.key === shiftKey) || null;
        callback && callback(docs)
      })

  }

  @observable fetchCampusListRef: any = null;
  @action
  fetchCampusList(campusKey, callback?: any) {
    this.fetchCampusListRef = this.ds.campusRef()
      .valueChanges()
      .subscribe((docs: any[]) => {
        this.campus = docs;
        this.selectedCampus = docs.find(m => m.key === campusKey) || null;
        callback && callback(docs)
      })

  }

  @action
  async fetchSelectedProgram(campusKey, programKey) {
    const programDoc = await this.ds.campusRef().doc(campusKey).collection("training_programs").doc(programKey).get().toPromise()
    this.selectedProgram = pushToObject(programDoc);

  }

  @observable fetchTermsRef: any = null;
  @observable termTitle: any = null;
  @observable terms: any = null;
  @action
  async fetchTerms(termKey, callback?: any) {
    const { programOption } = this.selectedProgram;
    this.termTitle = programOption.key === 1 ? 'Academic Year:' : 'Semester:';
    this.fetchTermsRef = this.ds.academicYearByTypeRef(programOption.key)
      .valueChanges()
      .subscribe((docs: any[]) => {
        this.terms = docs;
        this.selectedAcademicYear = docs.find(m => m.key === termKey) || null;
        callback && callback(docs)
      })
  }

  @observable fetchStudentEnglishRef: any = null;
  @action
  fetchStudentEnglish(batchKey, callback) {
    this.fetchStudentEnglishRef = this.ds
      .collectionRef('institute_training_level_batch').doc(batchKey)
      .collection('students', ref => ref.orderBy("student.full_name"))
      .valueChanges()
      .subscribe(doc => {
        callback(doc)
      })
  }

  @observable fetchBatchStudentGradeRef: any = null;
  @action
  async fetchBatchStudentGrade(queryParams, monthKey, gradeSheetTypeKey, callback?) {
    this.loading = true;
    const { batchKey } = queryParams;
    this.selectedBatch = pushToObject(await this.ds.batchLevelRef().doc(batchKey).get().toPromise());
    this.gradeSheets = pushToArray(await this.ds.gradeSheetStudentBatchRef(batchKey, monthKey, gradeSheetTypeKey).get().toPromise())
    this.fetchBatchStudentGradeRef = this.ds.gradeSheetStudentBatchMovementRef(batchKey, monthKey, gradeSheetTypeKey)
      .valueChanges()
      .subscribe(docs => {
        this.data = docs;
        this.schedules = MappingService.groupByDesc(docs, 'subjectKey', 'schedule_subject.max_score');
        this.loading = false;
        callback && callback(docs);
      })
  }


  @observable schedules: any[] = [];
  @observable gradeSheets: any[] = [];
  @observable fetchBatchLevelSchedulesRef: any = null;
  @action
  async fetchBatchLevelSchedules(queryParams, callback?) {
    this.loading = true;
    const { batchKey, scheduleKey, termKey, gradeSheetKey } = queryParams;
    // this.ds.firestoreRef().collection('grade_sheet_batches',ref=>ref
    // .where('studentKey','==','rEWUcEDTAbbjDVuzMnI8lqhlOmy1')
    // .where('batchKey','==',batchKey)
    // .where('gradeSheet.gradeSheetType.key','==',1)
    // .where('gradeSheet.monthKey','<=',202003)
    // .orderBy('gradeSheet.monthKey')).valueChanges().subscribe(doc=>{
    // })

    // this.ds.firestoreRef().collection(`students`).doc('rEWUcEDTAbbjDVuzMnI8lqhlOmy1')
    // .collection('schedules').doc(scheduleKey)
    // .collection('attendance',ref=>ref
    // .where('checkInStatus.key', '==', 2)
    // .where('attendanceData.date_key', '>=', new Date())
    // .where('attendanceData.date_key', '<=', new Date())
    // .orderBy('attendanceData.date_key')).valueChanges().subscribe(docs=>{
    // })

    const schedulesDocs = await this.ds.batchLevelDocRef(batchKey)
      .collection("schedules", ref => ref.orderBy('schedule_subject.subject.name_en')).get().toPromise();
    this.schedules = pushToArray(schedulesDocs);
    this.selectedSchedule = this.schedules.find(m => m.key === scheduleKey);
    this.fetchBatchLevelSchedulesRef = this.ds.gradeSheetsRef(batchKey)
      .valueChanges()
      .subscribe(docs => {
        this.gradeSheets = MappingService.orderBy(docs, 'monthKey')
        this.loading = false;
        callback && callback(docs);
      })
  }

  @observable fetchBatchLevelProgramRef: any = null;
  @action
  async fetchBatchLevelProgram(campusKey, termKey, programKey, shiftKey, callback?) {
    this.loading = true;
    this.batchMembers = [];
    this.data = [];
    this.selectedLevel = null;
    this.selectedBatch = null;
    const levelDoc = await this.ds.campusRef().doc(campusKey)
      .collection("training_levels", ref => ref.where("program.key", "==", programKey)).get().toPromise();
    this.level = MappingService.orderBy(pushToArray(levelDoc), "grade.order")
    this.fetchBatchLevelProgramRef = this.ds.batchProgramRef(campusKey, termKey, programKey, shiftKey)
      .valueChanges().subscribe(docs => {
        this.batches = docs;

        // this.batches = docs.filter((m:any) => !m.allowEnroll);
        this.loading = false;
        callback && callback(this.level)
      })
  }

  @action
  async fetchstudentMissAttendanceData(campusKey: any, date, callback) {
    const permissionData = pushToArray(await this.ds.studentMissAttendanceRef(campusKey, date).get().toPromise())
  }
  @action
  async fetchData(user: any, params, callback) {
    this.loading = true;
    this.batches = [];
    this.batchMembers = [];
    this.data = [];
    this.level = [];
    this.selectedLevel = null;
    this.selectedBatch = null;
    this.selectedSession = null;
    this.selectedAcademicYear = null;

    const { campus, memberOf, schoolKey, groupAccountKey } = user;
    let groupAccount_programKey = [];

    // for await (const key of groupAccountKey) {
    //   const groupAccountDoc = await this.ds.storeDocRef(schoolKey).collection("group_users_account").doc(key).get().toPromise();
    //   const groupAccountData = MappingService.pushToObject(groupAccountDoc);
    //   if(!groupAccountData?.programKeys) continue;
    //   groupAccountData?.programKeys.forEach(element => {
    //     const isExist = groupAccount_programKey.some(item => item === element);
    //     if(!isExist) groupAccount_programKey.push(element);
    //   });
    // }

    const configDoc = await this.ds.storeDocRef(schoolKey).collection("academic_environment").doc("academic_environment").get().toPromise();
    this.configData = MappingService.pushToObject(configDoc);

    const shiftDoc = await this.ds.shiftRef().get().toPromise()
    this.shifts = MappingService.pushToArray(shiftDoc);

    const campusDoc = await this.ds.storeDocRef(schoolKey).collection("campus", ref => ref.orderBy("order")).get().toPromise()
    this.campus = MappingService.pushToArray(campusDoc);

    if (!memberOf || memberOf.key !== memberOfObj.administrator.key) {
      if (user.email === 'abbeylawcol00@gmail.com') {
        this.campus = this.campus//.filter(m => m.key === campus.key)
      } else {
        this.campus = this.campus.filter(m => m.key === campus.key)
      }
    }

    this.selectedCampus = this.campus[0];
    this.selectedShift = this.shifts[0];

    const programDoc = await this.ds.campusRef().doc(this.selectedCampus.key).collection("training_programs").get().toPromise();
    this.programs = checkActiveProgram(MappingService.pushToArray(programDoc));
    // this.programs = this.programs.filter((program) => {
    //   const isExist = groupAccount_programKey.some(item => item === program.key);
    //   return isExist;
    // });

    this.selectedProgram = params && params.programKey ? this.programs.find(f => f.key === params.programKey) : this.programs[0] || null;

    const { year, term } = this.configData;
    if (this.selectedProgram && this.selectedProgram.programOption && this.selectedProgram.programOption.key === PROGRAM_OPTION_OBJ.yearBase.key) {
      this.selectedAcademicYear = year
    } else {
      this.selectedAcademicYear = term
    }

    const yearDoc = await this.ds.storeDocRef(schoolKey).collection("academic_year").get().toPromise()
    const yearData = MappingService.pushToArray(yearDoc);
    this.academicYears = yearData;

    this.loading = false;
    callback(this.selectedAcademicYear)
  }

  @action
  async fetchCampus(item, callback) {
    this.loading = true;
    this.batches = [];
    this.batchMembers = [];
    this.data = [];
    this.sessions = [];
    this.selectedProgram = null;
    this.selectedLevel = null;
    this.selectedBatch = null;
    this.selectedSession = null;
    this.selectedCampus = item;
    this.selectedShift = null;
    this.selectedAcademicYear = null;

    const programDoc = await this.ds.campusRef().doc(this.selectedCampus.key).collection("training_programs").get().toPromise()
    this.programs = MappingService.pushToArray(programDoc);

    this.selectedProgram = this.programs[0] || null;

    const { year, term } = this.configData;
    if (this.selectedProgram && this.selectedProgram.programOption && this.selectedProgram.programOption.key === PROGRAM_OPTION_OBJ.yearBase.key) {
      this.selectedAcademicYear = year
    } else {
      this.selectedAcademicYear = term
    }
    const levelDoc = await this.ds.campusRef().doc(this.selectedCampus.key).collection("training_levels", ref => ref.where("program.key", "==", this.selectedProgram.key)).get().toPromise()
    this.level = MappingService.pushToArray(levelDoc);
    this.level = MappingService.orderBy(this.level, "grade.order")
    this.selectedShift = this.shifts[0];

    this.loading = false;
    callback(this.level)
  }

  @action
  async fetchTrainingLevel(item, callback) {
    this.loading = true;
    this.batches = [];
    this.level = [];
    this.batchMembers = [];
    this.data = [];
    this.selectedLevel = null;
    this.selectedBatch = null;
    this.selectedSession = null;
    this.selectedSession = null;

    const { year, term } = this.configData;
    if (this.selectedProgram && this.selectedProgram.programOption && this.selectedProgram.programOption.key === PROGRAM_OPTION_OBJ.yearBase.key) {
      this.selectedAcademicYear = year
    } else {
      this.selectedAcademicYear = term
    }

    // const yearDoc = await this.ds.academicYearRef().get().toPromise()
    // const yearData = MappingService.pushToArray(yearDoc);
    // this.academicYears = yearData;

    const levelDoc = await this.ds.campusRef().doc(this.selectedCampus.key).collection("training_levels", ref => ref.where("program.key", "==", this.selectedProgram.key)).get().toPromise()
    this.level = MappingService.pushToArray(levelDoc);
    this.level = MappingService.orderBy(this.level, "grade.order")
    this.selectedShift = this.shifts[0];

    this.loading = false;
    callback(this.level)
  }

  @action
  validBatchSession(sessionKey: string, callback) {
    this.ds.batchSessionFireRef(sessionKey).get().then(docs => {
      const data = docs.empty ? null : docs.docs.map(m => ({ ...m.data() }));

      let isValid = false;
      if (data && data.length > 0) isValid = true;
      callback(isValid)
    })
  }

  @action
  validScheduleBatchStudent(batchKey: string, callback) {
    this.ds.batchLevelFireRef().doc(batchKey).collection("schedules").get().then(schedules => {

      this.ds.batchLevelFireRef().doc(batchKey).collection("students").get().then(students => {
        const scheduleDocs = schedules.empty ? null : schedules.docs.map(m => ({ ...m.data() }));
        const studentDocs = students.empty ? null : students.docs.map(m => ({ ...m.data() }));

        let isValid = false;
        if ((scheduleDocs && scheduleDocs.length > 0) || (studentDocs && studentDocs.length > 0)) isValid = true;
        callback(isValid)
      })
    })
  }

  @action
  fetchStudent(limit: number, callback) {
    this.process = true;
    this.ds.batchStudentAdmissionRef(this.selectedShift.key, this.selectedLevel.key, this.selectedProgram.key, limit).valueChanges().subscribe(docs => {
      this.students = docs;
      this.process = false;
      callback(docs)
    })
  }

  @action
  fetchBatchCounter(batchKey) {
    return this.ds.batchLevelRef().doc(batchKey).collection("students")
  }

  @action
  fetchBatchMember() {
    this.loading = true;
    this.ds.batchLevelDocRef(this.selectedBatch.key).collection("students").valueChanges().subscribe(docs => {
      this.batchMembers = MappingService.orderBy(docs, "student.full_name");
      this.loading = false;
    })
  }


  @action
  async fetchStudentsInTimeAttendance(date, params) {
    this.process = true;
    if (this.selectedBatch || params && params?.batchKey) {
      // const fromDate = moment(date).startOf("day").toDate();
      // const toDate = moment(date).endOf("day").toDate();
      const dateKey = toDateKey(date);
      let studentAccounts: any[] = [];
      this.batchMembers = []
      await this.ds.firestore().collection('institute_training_level_batch').doc(this.selectedBatch?.key || params?.batchKey).collection("batch_members").orderBy('student.full_name').get().then(async (doc: any) => {
        const fetchedStudents = pushToArray(doc);
        await Promise.all(fetchedStudents.map(async (m) => {
          const timeAttendanceDoc = pushToArray(await this.ds.firestore().collection('students').doc(m.student.key).collection("time_attendance").where('admissionKey', '==', m.admissionKey).where("create_date_key", "==", dateKey).get())
          const timeAttendanceMovementDoc = pushToArray(await this.ds.firestore().collection('students').doc(m.student.key).collection("student_attendance_movement").where('admissionKey', '==', m.admissionKey).where("create_date_key", "==", dateKey).get())
          // const timeAttendance = timeAttendanceDoc.map(f => {
          //   return timeAttendanceObj(f)
          // }
          // )
          // const timeAttendanceMovement = timeAttendanceMovementDoc.map(f => {
          //   return timeAttendanceMovementObj(f)
          // }
          // )
          studentAccounts.push({
            // academicYear: {
            //   key: m.academicYear.key,
            //   name: m.academicYear.name,
            //   academic_year: m.academicYear.academic_year,
            // },
            // admissionKey: m.admissionKey,
            // batchData: m.batchData,
            // batchKey: m.batchKey,
            // batchKeyItems: m.batchKeyItems,
            // batchNew: m.batchNew,
            // student: m.student,
            // shift: m.shift,
            // program:m.program,
            ...m,
            timeAttendance: timeAttendanceDoc.length > 0 ? orderBy(timeAttendanceDoc, "create_date") : [],
            timeAttendanceMovement: timeAttendanceMovementDoc.length > 0 ? orderBy(timeAttendanceMovementDoc, "create_date") : [],

            // timeAttendanceMovement: timeAttendanceMovementDoc.length > 0 ? orderBy(timeAttendanceMovementDoc, "create_date") : [],
            // timeAttendance: timeAttendanceDoc.length > 0 ? orderBy(timeAttendanceDoc, "create_date") : [],

          });
        }));
        this.batchMembers = orderBy(studentAccounts.filter(m => !m.has_drop_out_school), 'student.khmer_full_name');
        this.process = false;
      }).catch((error => alert(error)));
    } else {
      return;
    }

  }

  @action
  async onConfirmTimeAttendance(schedule, currentSession, schoolKey, user: any, date, form, isScanCardOn, callback) {
    this.process = true;
    const batch = this.ds.batch();

    const { campus, batchKey, key, instructor, academicGrade } = schedule;

    const { permission_status, permissionNote, teacherRemark } = form
    const policyRemark = permission_status
    //
    const policyHours = pushToArray(await this.ds.schoolFireDocRef().collection("time_and_attendances_policy").where("groupTimePolicy.key", '==', academicGrade.program.key).where("status.key", "==", 1).get());

    const sysConfig = pushToObject(await this.ds.schoolFireDocRef().collection("sys_option").doc("general").get());
    const presentRemark = sysConfig.attendanceP;

    const onConfirmTimeAttendanceDate = date ? date : new Date()
    const dateString = moment(onConfirmTimeAttendanceDate).format("YYYYMMDD");

    const { fromHours } = currentSession;
    const dateTime = moment(`${dateString}-${fromHours}`, "YYYYMMDD-hh:mm:ss A").toDate();
    const createDate = dateTime;

    let currentPolicyHour = policyHours.filter(p =>
      p.fromHoursNumber <= currentSession.fromHoursNumber &&
      p.toHoursNumber >= currentSession.fromHoursNumber)[0];

    const selectedPolicyHour = {
      fromHour: currentPolicyHour,
      toHour: currentPolicyHour
    };

    const schedule_subject = {
      key: schedule.schedule_subject.key,
      grade: schedule.schedule_subject.grade,
      subject: schedule.schedule_subject.subject
    }

    let presentCheckInInShift_policyHours = policyHours.filter((policyHour) =>
      policyHour.shift.key === currentPolicyHour.shift.key &&
      policyHour.remark.key === presentRemark.key &&
      policyHour.type.text === 'Check In');

    let presentCheckInInShift_policyHour = presentCheckInInShift_policyHours.length > 0 ? presentCheckInInShift_policyHours[0] : null;

    if (!isScanCardOn) presentCheckInInShift_policyHour = { ...presentCheckInInShift_policyHour, remark: sysConfig.attendanceP, remarkKey: sysConfig.attendanceP.key };
    else presentCheckInInShift_policyHour = { ...presentCheckInInShift_policyHour, remark: sysConfig.attendanceA, remarkKey: sysConfig.attendanceA.key };
    if (this.batchMembers && this.batchMembers.length > 0) {
      await Promise.all(this.batchMembers.map(async (doc) => {
        let timeAttendance;
        const studentAdmissionDoc = pushToObject(await this.ds.studentAdmissionDocRef(doc.admissionKey).get().toPromise())
        const studentAdmission = admissionObj(studentAdmissionDoc)
        const timeAttendanceDoc = pushToArray(await this.ds.collectionRef('students').doc(doc.student.key).collection("time_attendance",
          ref => ref.where('create_date_key', '==', toDateKey(createDate)).where('admissionKey', '==', doc.admissionKey)).get().toPromise());

        const timeAttendanceMovement = pushToArray(await this.ds.collectionRef('students').doc(doc.student.key).collection("student_attendance_movement",
          ref => ref.where('create_date_key', '==', toDateKey(createDate)).where('admissionKey', '==', doc.admissionKey).where('session.key', '==', currentSession.key)).get().toPromise());
        timeAttendance = timeAttendanceDoc[0]
        // console.log('timeAttendance', timeAttendance, timeAttendanceMovement);
        const attendanceKey = this.ds.createId();
        const studentAttendanceMovement = this.ds.schoolFireDocRef().collection("student_attendance_movement").doc(attendanceKey);
        const storeStudentAttendanceMovement = this.ds.studentFireRef().doc(doc.student.key).collection("student_attendance_movement").doc(attendanceKey);
        const prioritizedMovements = pushToArray(await this.ds.collectionRef('students').doc(doc.student.key).collection("student_attendance_movement",
          ref => ref.where('isPrioritizedMovement', '==', true).where('create_date_key', '==', toDateKey(createDate))).get().toPromise());
        const prioritizedPermissionMovements = prioritizedMovements.filter((movement) => movement.prioritizedShift);

        // let remark = policyRemark ? attendantRemarkObj(policyRemark) : timeAttendance.remark;
        // let remarkKey = policyRemark ? policyRemark.key : timeAttendance.remarkKey;

        let remark = timeAttendance ? timeAttendance?.remark : attendantRemarkObj(policyRemark);
        let remarkKey = timeAttendance ? timeAttendance?.remarkKey : policyRemark.key;

        // if (doc && doc.isPermission) timeAttendance = doc.timeAttendance[0];
        if (timeAttendance && timeAttendance.isHasPermission) {
          const isValid = currentSession.fromHoursNumber >= timeAttendance.permissionShift.shift.fromHour_
            && currentSession.fromHoursNumber < timeAttendance.permissionShift.shift.toHour_;
          if (isValid) {
            remark = timeAttendance.permissionShift.remark;
            remarkKey = timeAttendance.permissionShift.remarkKey;
          }
          // console.log('isHasPermission', isValid)

        }

        if (prioritizedPermissionMovements) {
          prioritizedPermissionMovements.forEach((item) => {
            const isValid = currentSession.fromHoursNumber >= item.prioritizedShift.fromHour_
              && currentSession.fromHoursNumber < item.prioritizedShift.toHour_;
            if (isValid) { remark = item.remark; remarkKey = remark.key; }
            // console.log('prioritizedPermissionMovements', isValid)
          })
        }

        if (!timeAttendance) {
          // console.log('1');
          const studentKey = doc.student.key;
          const timeAttendanceKey = this.ds.createId();
          const studentTimeAttendance = this.ds.studentFireRef().doc(studentKey).collection("time_attendance").doc(timeAttendanceKey);
          const storeTimeAttendance = this.ds.schoolFireDocRef().collection("time_attendance").doc(timeAttendanceKey);
          const timeAttendance: IStudentTimeAttendance = {
            key: timeAttendanceKey,
            scan_card_key: null,
            create_type: CREATE_TIME_TYPE.administrator,
            create_by: user.key,
            create_date: createDate,
            create_date_key: toDateKey(createDate),
            studentKey: studentKey,
            campusKey: campus.key,
            schoolKey: schoolKey,
            type: HR_USER_TYPES.students,
            page_key: ConvertService.pageKey(),
            policy_hour: selectedPolicyHour,
            policy: attendancePolicyObj(presentCheckInInShift_policyHour),
            policyKey: presentCheckInInShift_policyHour.key,
            policy_type: presentCheckInInShift_policyHour.type,
            remark: remark,
            remarkKey: remarkKey,
            note: permissionNote,
            scan_date: createDate,
            scan_date_key: toDateKey(createDate),
            confirm_date: new Date(),
            confirm_by: user.key,
            sessionKey: currentSession.key || null,
            isParent: false,
            isHasPermission: false,
            studentAdmission: studentAdmission,
            admissionKey: doc.admissionKey,
            confirm_from: CONFIRM_ATT.WEB
          };

          const student: IStudentAttendanceMovement = {
            key: attendanceKey,
            status: { key: 1, text: "Active" },
            create_by: user.key,
            create_date: createDate,
            create_date_key: toDateKey(createDate),
            create_date_month_key: toMonthKey(createDate),
            page_key: ConvertService.pageKey(),
            studentKey: doc.student.key,
            campusKey: campus.key,
            schoolKey: schoolKey,
            batchKey: batchKey,
            admissionKey: doc.admissionKey,
            studentAdmission: studentAdmission,
            batchData: doc.batchData,
            batchKeyItems: doc.batchKeyItems,
            academicYearKey: doc.academicYear.key,
            programKey: doc.program.program.key,
            levelKey: doc.program.key,
            scheduleKey: key,
            schedule_subjectKey: schedule_subject.key,
            schedule_subject: schedule_subject,
            checkIn_date: createDate,
            checkIn_date_key: toDateKey(createDate),
            confirm_date: new Date(),
            confirm_by: user.key,
            timeAttendanceKey: timeAttendance.key || "",
            type: timeAttendance.type || null,
            policy_hour: timeAttendance.policy_hour || null,
            policy: timeAttendance.policy || null,
            policyKey: timeAttendance.policyKey || "",
            remark: remark || null,
            remarkKey: remarkKey || null,
            note: timeAttendance && timeAttendance.note || permissionNote,
            policy_type: timeAttendance.policy_type || null,
            session: currentSession ? sessionObj(currentSession) : null,
            sessionKey: currentSession.key || null,
            isQRscan: false,
            permissionKey: null,
            confirm_from: CONFIRM_ATT.WEB
          };

          batch.set(studentTimeAttendance, timeAttendance);
          batch.set(storeTimeAttendance, timeAttendance);
          batch.set(studentAttendanceMovement, student);
          batch.set(storeStudentAttendanceMovement, student);
        } else {
          if (timeAttendanceMovement.length === 0) {
            // console.log('2');
            const student: IStudentAttendanceMovement = {
              key: attendanceKey,
              status: { key: 1, text: "Active" },
              create_by: user.key,
              create_date: createDate,
              create_date_key: toDateKey(createDate),
              create_date_month_key: toMonthKey(createDate),
              studentKey: doc.student.key,
              campusKey: campus.key,
              schoolKey: schoolKey,
              batchKey: batchKey,
              page_key: ConvertService.pageKey(),
              admissionKey: doc.admissionKey,
              studentAdmission: studentAdmission,
              batchData: doc.batchData,
              batchKeyItems: doc.batchKeyItems,
              academicYearKey: doc.academicYear.key,
              programKey: doc.program.program.key,
              levelKey: doc.program.key,
              scheduleKey: key,
              schedule_subjectKey: schedule_subject.key,
              schedule_subject: schedule_subject,
              checkIn_date: createDate,
              checkIn_date_key: toDateKey(createDate),
              confirm_date: new Date(),
              confirm_by: user.key,
              note: timeAttendance && timeAttendance.note || permissionNote,
              timeAttendanceKey: timeAttendance.key || null,
              type: timeAttendance.type || null,
              policy_hour: timeAttendance.policy_hour || null,
              policy: attendancePolicyObj(timeAttendance.policyData),
              policyKey: timeAttendance.policyKey || null,
              remark: remark || null,
              remarkKey: remarkKey || null,
              policy_type: timeAttendance.policy_type || null,
              session: currentSession ? sessionObj(currentSession) : null,
              sessionKey: currentSession.key || null,

              isQRscan: false,
              permissionKey: null,
              confirm_from: CONFIRM_ATT.WEB
            };
            // console.log('student', student);
            batch.set(studentAttendanceMovement, student);
            batch.set(storeStudentAttendanceMovement, student);
          }
        }
      }));
    }


    const employeeTimeAttendance = this.ds.employeeFireRef().doc(instructor.key).collection("attendance_confirmation_logs");
    const instructorData = {
      key: this.selectedSchedule.instructor.key,
      code: this.selectedSchedule.instructor.code,
      full_name: this.selectedSchedule.instructor.full_name,
      mobile_phone: this.selectedSchedule.instructor.mobile_phone,
      khmer_full_name: this.selectedSchedule.instructor.khmer_full_name,
      gender: this.selectedSchedule.instructor.gender,
    }

    const attendanceConfirmationLog: IAttendanceConfirmationLog = {
      key: this.ds.createId(),
      create_by: userObj(user),
      create_date: new Date(),
      create_date_key: toDateKey(new Date()),
      create_date_month_key: toMonthKey(new Date()),
      date_key: toDateKey(createDate),
      page_key: ConvertService.pageKey(),
      campusKey: campus.key,
      schoolKey: schoolKey,
      academicYearKey: this.selectedSchedule && this.selectedSchedule.academicYear.key,
      programKey: this.selectedSchedule && this.selectedSchedule.academicGrade.program.key,
      levelKey: this.selectedSchedule && this.selectedSchedule.academicGrade.key,
      batchKey: batchKey,
      scheduleKey: key,
      session: currentSession ? sessionObj(currentSession) : null,
      sessionKey: currentSession.key || null,
      instructor: instructorData,
      instructorKey: instructorData.key,
      confirm_date: new Date(),
      note: permissionNote,
      status: ATTENDANCE_CONFIRMATION_STATISTICS.submitted,
      calendar_date: date,
      schedule_subject: schedule_subject,
      remark: attendantRemarkObj(teacherRemark),
      confirm_from: CONFIRM_ATT.WEB
    };

    batch.set(employeeTimeAttendance.doc(attendanceConfirmationLog.key), attendanceConfirmationLog, { merge: true });
    batch.set(this.ds.storeRef().collection('attendance_confirmation_logs').doc(attendanceConfirmationLog.key).ref, attendanceConfirmationLog, { merge: true });
    await batch.commit().then(() => {
      callback(true, null);
      this.fetchStudentsInTimeAttendance(date, null);
      if (date) {
        this.confirmedAttendances(date);
      }
      this.process = false;
    }).catch(error => {
      this.process = false;
      callback(false, error);
    });

  }

  // @action
  // async updateAttendencePolicy(user, note, remark: any, data, timeAttendanceMovement, date, callback) {
  //   const batch = this.ds.batch();
  //   this.process = true;
  //   const { student } = data;
  //   // const policyHours = pushToArray(await this.ds.schoolFireDocRef().collection("time_and_attendances_policy").where("status.key", "==", 1).get());
  //   // const sysConfig = pushToObject(await this.ds.schoolFireDocRef().collection("sys_option").doc("general").get());
  //   // const dateString = moment(date).format("YYYYMMDD")
  //   // const timeString = moment(new Date()).format("hh:mm:ss A");

  //   // const { fromHours } = timeAttendanceMovement.session;
  //   // const dateTime = moment(`${dateString}-${fromHours}`, "YYYYMMDD-hh:mm:ss A").toDate()
  //   // const createDate = dateTime
  //   // const currentHour = getTimePolicy(timeAttendanceMovement.session?.fromHoursNumber);

  //   // const currencyPolicy = policyHours.filter(p => p.type.key === 1 && p.remark.key === remark.key)[0];
  //   // let currencyPolicy = policyHours.filter(p => p.type.key === 1
  //   //   && p.fromHoursNumber >= currentHour.fromHour
  //   //   && p.toHoursNumber <= currentHour.toHour)[0];
  //   // currencyPolicy.remark = remark;

  //   if (timeAttendanceMovement) {
  //     const _key = timeAttendanceMovement.key;
  //     // const timeAttendanceKey = timeAttendanceMovement.timeAttendanceKey;

  //     // const studentTimeAttendance = this.ds.studentFireRef().doc(student.key).collection("time_attendance").doc(timeAttendanceMovement.timeAttendanceKey);
  //     // const storeTimeAttendance = this.ds.schoolFireDocRef().collection("time_attendance").doc(timeAttendanceMovement.timeAttendanceKey);
  //     const data = {
  //       // policy_hour: currentHour,
  //       // policy: attendancePolicyObj(currencyPolicy),
  //       // policyKey: currencyPolicy.key,
  //       remark: attendantRemarkObj(currencyPolicy.remark),
  //       remarkKey: currencyPolicy?.remark?.key || "",
  //       // policy_type: currencyPolicy.type,
  //       // note: note || null,
  //       update_by: user.key,
  //       update_date: new Date(),
  //       page_key: ConvertService.pageKey(),
  //     }

  //     // batch.update(studentTimeAttendance, data);
  //     // batch.update(storeTimeAttendance, data);

  //     batch.update(this.ds.studentFireRef().doc(student.key).collection("student_attendance_movement").doc(_key), data)
  //     batch.update(this.ds.schoolFireDocRef().collection("student_attendance_movement").doc(_key), data)

  //     batch.commit().then(() => {
  //       callback(true, null);
  //       this.process = false;
  //       this.fetchStudentsInTimeAttendance(date, null);
  //     }).catch(error => {
  //       this.process = false;
  //       callback(false, error);
  //     });
  //   }
  // }


  @action
  async updateAttendencePolicy(user, note, remark: any, data, timeAttendanceMovement, date, callback) {
    const batch = this.ds.batch();
    this.process = true;
    const { student } = data;
    if (timeAttendanceMovement) {
      const _key = timeAttendanceMovement.key;
      const data = {
        remark: attendantRemarkObj(remark),
        remarkKey: remark?.key || "",
        note: note || null,
        update_remark_by: user.key,
        update_remark_date: new Date(),
      }
      batch.update(this.ds.studentFireRef().doc(student.key).collection("student_attendance_movement").doc(_key), data)
      batch.update(this.ds.schoolFireDocRef().collection("student_attendance_movement").doc(_key), data)
      batch.commit().then(() => {
        callback(true, data);
        this.process = false;
        this.fetchStudentsInTimeAttendance(date, null);
        // const memberIndex = this.batchMembers.findIndex(member => member.student.key === student.key);
        // if (memberIndex !== -1) {
        //   const attIndex = this.batchMembers[memberIndex].timeAttendanceMovement.findIndex(att => att.key === _key);
        //   if (attIndex !== -1) {
        //     this.batchMembers[memberIndex].timeAttendanceMovement[attIndex] = {
        //       ...this.batchMembers[memberIndex].timeAttendanceMovement[attIndex],
        //       // ...data
        //       remark: attendantRemarkObj(remark),
        //       remarkKey: remark?.key || "",
        //       note: note || null,
        //     };
        //   }
        // }
        // this.batchMembers = []
      }).catch(error => {
        this.process = false;
        callback(false, error);
      });
    }
  }

  @action
  fetchSchedule(termKey) {
    this.loading = true;
    this.ds.termRef().doc(termKey).collection("schedules", ref => ref.where("generation.key", "==", this.selectedBatch.key)).valueChanges().subscribe(docs => {
      this.data = MappingService.orderBy(docs, "page_key");
      this.empty = docs.length === 0;
      this.loading = false;
    })
  }

  @action
  fetchBatchSchedule(yearKey: string) {
    this.loading = true;
    this.ds.batchLevelDocRef(this.selectedBatch.key).collection("schedules", ref => ref.where("academicYear.key", "==", yearKey)).valueChanges().subscribe(docs => {
      this.data = docs;
      this.empty = docs.length === 0;
      this.loading = false;
    })
  }

  @observable confirmScheduleDataRef: Subscription;
  @action
  fetchConfirmBatchSchedule(date, params, callback) {
    if (this.selectedBatch || params && params?.batchKey) {
      const sessionDays = getDayName(moment(date).toDate())
      this.loading = true;
      this.confirmScheduleData = [];
      const monthKey = moment(date).month() + 1;
      this.confirmScheduleDataRef = this.ds.batchLevelDocRef(this.selectedBatch?.key || params?.batchKey).collection("schedules", ref => ref.where('sessionDays', 'array-contains', sessionDays)).valueChanges().subscribe(async docs => {
        await Promise.resolve(docs.map(async schedule => {
          const odd_even_month = monthKey % 2 === 0 ? 2 : 1; //event == 2 -- odd == 1
          const rotateShiftRef = odd_even_month === schedule?.startRotateShiftMonth?.key ? schedule?.startRotateShift?.key : (schedule?.startRotateShift?.key === 1 ? 2 : 1);
          const rotateSession = schedule.sessionItems.map(m => {
            return {
              ...m,
              fromHours: rotateShiftRef === 1 ? m.fromHours : m.rotateShift.fromHours,
              fromHoursNumber: rotateShiftRef === 1 ? m.fromHoursNumber : m.rotateShift.fromHoursNumber,
              toHours: rotateShiftRef === 1 ? m.toHours : m.rotateShift.toHours,
              toHoursNumber: rotateShiftRef === 1 ? m.toHoursNumber : m.rotateShift.toHoursNumber,
            }
          });
          const data = {
            ...schedule,
            sessionItems: rotateSession
          }
          this.confirmScheduleData.push(data)
        }))
        this.selectedSchedule = params && params?.scheduleKey ? this.confirmScheduleData.find(f => f.key === params?.scheduleKey) : this.confirmScheduleData[0] || null;
        const selectedScheduleSessionItems = this.selectedSchedule ? this.selectedSchedule.sessionItems.filter(f => f.days[sessionDays] === true) : [];
        this.selectedScheduleSessionItems = selectedScheduleSessionItems.sort((a, b) => a.fromHoursNumber - b.fromHoursNumber);
        callback(this.confirmScheduleData)
        this.empty = docs.length === 0;
        this.loading = false;
      })
    }
  }

  @action
  async confirmedAttendances(date) {
    const dateKey = toDateKey(date);
    const confirmedAttendances = this.selectedSchedule ? await this.ds.schoolFireDocRef().collection("student_attendance_movement").where("create_date_key", "==", dateKey).where("scheduleKey", "==", this.selectedSchedule.key).get() : null;
    const attendanceConfirmLog = this.selectedSchedule ? await this.ds.schoolFireDocRef().collection("attendance_confirmation_logs").where("date_key", "==", dateKey).where("scheduleKey", "==", this.selectedSchedule.key).get() : null;
    this.confirmedAttendancesData = confirmedAttendances ? pushToArray(confirmedAttendances) : [];
    this.attendanceConfirmationLogs = attendanceConfirmLog ? pushToArray(attendanceConfirmLog) : [];
  }

  @action
  async fetchPolicyRemark(schoolKey, callback) {
    const policyRemark = await this.ds.storeDocRef(schoolKey).collection("policy_remarks").get().toPromise()
    const data = pushToArray(policyRemark)
    if (SCHOOL.selfPermissionToEachSession) callback(data);
    else callback(data)
  }

  @action
  async fetchAllPolicyRemarks(schoolKey) {
    const policyRemark = await this.ds.storeDocRef(schoolKey).collection("policy_remarks").get().toPromise()
    const data = pushToArray(policyRemark)
    return data;
  }

  @action
  fetchShift(callback) {
    this.loading = true;
    this.selectedProgram = null;
    this.selectedBatch = null;
    this.selectedLevel = null;
    this.selectedSession = null;
    this.batches = [];
    this.batchMembers = [];
    this.data = [];
    this.sessions = [];
    this.ds.shiftRef().valueChanges().subscribe(docs => {
      this.shifts = docs;
      this.loading = false;
      callback(docs)
    })
  }

  @action
  fetchSelectedShift(item) {
    this.loading = true;
    this.batches = [];
    this.batchMembers = [];
    this.data = [];
    this.sessions = [];
    this.selectedLevel = null;
    this.selectedBatch = null;
    this.selectedShift = item;
    this.loading = false;
  }

  @action
  fetchSelectedYear(item) {
    this.loading = true;
    this.batches = [];
    this.batchMembers = [];
    this.data = [];
    this.sessions = [];
    this.selectedProgram = null;
    this.selectedLevel = null;
    this.selectedBatch = null;
    this.selectedSession = null;
    this.selectedCampus = null;
    this.selectedShift = null;
    this.selectedAcademicYear = item;
    this.loading = false;
  }

  @action
  fetchProgram() {
    this.loading = true;
    this.batches = [];
    this.sessions = [];
    this.batchMembers = [];
    this.data = [];
    this.selectedProgram = null;
    this.selectedLevel = null;
    this.selectedBatch = null;
    this.selectedSession = null;
    this.selectedAcademicYear = null;
    this.loading = false;
  }

  @action
  fetchBatch() {
    this.loading = true;
    this.batches = [];
    this.batchMembers = [];
    this.data = [];
    this.ds.batchLevelKeyRef(this.selectedLevel.key, this.selectedAcademicYear.key, this.selectedCampus.key)
      .valueChanges()
      .subscribe(docs => {
        if (docs && docs.length > 0) {
          this.batches = docs.filter((m: any) => m.status.key == batchStatusObj.active.key && m.shift.key === this.selectedShift.key && !m.allowEnroll);
        }
        this.loading = false;
      })
  }


  @action
  async fetchBatchConfirm(params) {
    this.loading = true;
    this.batches = [];
    this.batchMembers = [];
    this.data = [];

    const { year, term } = this.configData;

    this.selectedAcademicYear = this.selectedProgram.programOption.key === 1 ? year : term;
    this.ds.batchConfirmLevelKeyRef(this.selectedAcademicYear.key, this.selectedCampus.key)
      .valueChanges()
      .subscribe(docs => {
        if (docs && docs.length > 0) {
          // const batches: any = docs.filter((m: any) => m.status.key == batchStatusObj.active.key && m.shift.key === this.selectedShift.key);
          // const batches: any = docs.filter((m: any) => m.status.key == batchStatusObj.active.key && m.shift.key === this.selectedShift.key && m.allowEnroll);
          const batches: any = docs;
          this.batches = batches.filter(m => m?.level?.program?.key === (this.selectedProgram.key || params?.programKey) && m.allowEnroll === true);

          this.batches = MappingService.orderBy(this.batches, 'name');
          this.groupBatches = groupBy(this.batches, 'level.key', 'level.order');

          if (params && params.programKey) {
            this.selectedProgram = this.programs.find(f => f.key === params?.programKey)
          }
          if (params && params.batchKey) {
            this.selectedBatch = this.batches.find(f => f.key === params.batchKey);
          }
          if (params && params.scheduleKey) {
            this.selectedSchedule = this.schedules.find(f => f.key === params.scheduleKey)
          }
        }
        this.loading = false;
      })
  }

  @action
  async fetchNewStudent(limit, callback) {
    this.process = true;
    const termDoc = await this.ds.paymentTermRef(this.selectedInstitute.key).get().toPromise();
    const term = MappingService.pushToObject(termDoc);
    this.selectedTerm = term;
    const studentDocs = await this.ds.termRef().doc(term.key).collection("institute_enrollment", ref => ref
      .where("isPaid", "==", true)
      .where("isCompleted", "==", false)
      .where("target_campus.key", "==", this.selectedCampus.key)
      .where("shift.key", "==", this.selectedShift.key)
      .where("program.category.key", "==", this.selectedInstitute.key)
      .where("program.start_level_key", "==", this.selectedLevel.key)
      .where("isPaid", "==", true)
      .orderBy("student.full_name")
      .limit(limit)).get().toPromise();
    this.students = MappingService.orderBy(MappingService.pushToArray(studentDocs), "student.full_name");
    this.process = false;
    callback(this.students);
  }

  @action
  async fetchNewEnglishStudent(limit, termKey, callback) {
    this.process = true;
    const studentDocs = await this.ds.newEnglishStudentRef(
      this.selectedInstitute.key, this.selectedCampus.key, this.selectedShift.key,
      this.selectedProgram.key, this.selectedLevel.key, limit, termKey
    ).get().toPromise();
    this.students = MappingService.orderBy(MappingService.pushToArray(studentDocs), "student.full_name");
    this.process = false;
    callback(this.students);
  }

  @action
  async fetchNewStudentAdmission(limit: number, callback) {
    this.process = true;
    this.students = [];
    const studentDocs = await this.ds.newAdmissionStudentRef(this.selectedAcademicYear.key, this.selectedCampus.key, this.selectedLevel.key, limit).get().toPromise();
    let studentData = MappingService.pushToArray(studentDocs)
    studentData = studentData.filter(m => m.isPaid)
    this.students = MappingService.orderBy(studentData, "student.full_name");
    // this.students = this.students.filter(m => m.Class === "11NGS_C2")
    this.process = false;
    callback(this.students);
  }

  @action
  async fetchComingBackEnglishStudent(limit, termKey, callback) {
    this.process = true;
    const comingBackList = await this.ds.studentInstituteComingBackRef(this.selectedInstitute.key, this.selectedShift.key, this.selectedCampus.key, termKey
      , this.selectedLevel.key, limit).get().toPromise();
    this.students = MappingService.pushToArray(comingBackList);
    this.process = false;
    callback(this.students);
  }

  @action
  async fetchSession(item) {
    this.loading = true;
    this.batches = [];
    this.batchMembers = [];
    this.selectedBatch = null;
    this.selectedSession = null;
    this.data = [];
    this.selectedLevel = item;
    const { program } = item;
    this.selectedProgram = program;
    const termDoc = await this.ds.paymentTermRef(this.selectedInstitute.key).get().toPromise();
    const term = MappingService.pushToObject(termDoc);
    this.selectedTerm = term;
    const studentDocs = await this.ds.newEnglishStudentRef(
      this.selectedInstitute.key, this.selectedCampus.key, this.selectedShift.key,
      this.selectedProgram.key, this.selectedLevel.key, 10000, this.selectedTerm.key
    ).get().toPromise();
    this.students = MappingService.orderBy(MappingService.pushToArray(studentDocs), "student.full_name");

    const comingBackList = await this.ds.studentInstituteComingBackRef(this.selectedInstitute.key, this.selectedShift.key, this.selectedCampus.key, term.key
      , this.selectedLevel.key, 10000).get().toPromise();
    this.comingStudent = MappingService.pushToArray(comingBackList);

    this.ds.sessionLevelKeyRef(item.key, this.selectedShift.key).valueChanges().subscribe(docs => {
      this.sessions = MappingService.orderBy(docs, "name");
      this.loading = false;
    })
  }

  @action
  addSession(item: ISession, callback) {
    this.process = true;
    this.ds.sessionLevelRef().doc(item.key).set(item).then(() => {
      this.process = false;
      callback(true, null);
    })
      .catch(error => {
        alert(error)
        this.process = false;
        callback(false, error);
      });
  }

  @action
  editSession(item: ISession, callback) {
    this.process = true;
    this.ds.sessionLevelRef().doc(item.key).update(item).then(() => {
      this.process = false;
      callback(true, null);
    })
      .catch(error => {
        alert(error)
        this.process = false;
        callback(false, error);
      });
  }

  @action
  deleteSession(callback) {
    this.process = true;
    this.ds.sessionLevelRef().doc(this.selectedSession.key).delete().then(() => {
      this.process = false;
      this.selectedSession = null;
      callback(true, null);
    })
      .catch(error => {
        alert(error)
        this.process = false;
        callback(false, error);
      });
  }

  @action
  addBatch(item: IBatch, callback) {
    this.process = true;
    this.ds.batchLevelRef().doc(item.key).set(item).then(() => {
      this.process = false;
      callback(true, null);
    })
      .catch(error => {
        alert(error)
        this.process = false;
        callback(false, error);
      });
  }

  @action
  updateBatchShift(item, shift, callback) {
    this.process = true;
    this.ds.batchLevelRef().doc(item.key).update({ shift: shift }).then(() => {
      this.process = false;
      callback(true, null);
    })
      .catch(error => {
        alert(error)
        this.process = false;
        callback(false, error);
      });
  }

  @action
  async updateBatch(item: IBatch, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const yearRef = this.ds.academicYearFireRef();
    const batchRef = this.ds.batchLevelFireRef();

    const scheduleDocs = await this.ds.batchLevelDocRef(item.key).collection("schedules").get().toPromise();
    const scheduleData = MappingService.pushToArray(scheduleDocs);

    if (scheduleData && scheduleData.length > 0) {

      scheduleData.forEach(m => {

        batch.update(batchRef.doc(item.key).collection("schedules").doc(m.key), {
          ...m,
          code: item.name
        });
        batch.update(yearRef.doc(item.academicYear.key).collection("schedules").doc(m.key), {
          ...m,
          code: item.name
        });
      })
    }

    batch.update(batchRef.doc(item.key), item);

    batch.commit().then(() => {
      this.process = false;
      callback(true, null);
    })
      .catch(error => {
        this.process = false;
        callback(false, error);
      });
  }

  // @action
  // async promoteToAbc(item: IPromoteBatch, callback) {
  //   this.process = true;
  //   const batch = this.ds.batch();
  //   const { term, create_by, previousTerm } = item;

  //   const batchDocs = await this.ds.batchLevelRef().doc(this.selectedBatch.key).get().toPromise();
  //   const batchData = MappingService.pushToObject(batchDocs);

  //   const settingDoc = await this.ds.settingFireStore().get().toPromise();
  //   const setting = MappingService.pushToObject(settingDoc);
  //   const { abc_course_fee } = setting;
  //   item.level = abc_course_fee;
  //   const studentDocs = await this.ds.batchLevelRef().doc(this.selectedBatch.key).collection("students").get().toPromise();
  //   const studentData = MappingService.pushToArray(studentDocs);
  //   const movementRef = this.ds.promoteBatchLevelFireRef().doc(item.key);
  //   const batchRef = this.ds.batchLevelFireRef().doc(this.selectedBatch.key);
  //   const abcRef = this.ds.abcFireRef();
  //   const batchLevelGradingRef = this.ds.batchTrainingLevelGradingRef();
  //   const batchMovementRef = this.ds.studentBatchMovementFireRef();
  //   const studentRef = this.ds.studentFireRef()

  //   const batchGradingKey = this.ds.createId();
  //   batch.set(batchLevelGradingRef.doc(batchGradingKey), {
  //     ...batchData,
  //     key: batchGradingKey,
  //     promote_by: create_by,
  //     promote_date: new Date(),
  //     term: previousTerm
  //   })

  //   studentData.forEach(m => {
  //     const stu = m;
  //     abcRef.doc(m.key).set({
  //       ...stu,
  //       finish_term: term,
  //       finish_by: item.create_by,
  //       finish_date: item.create_date,
  //       finish_date_key: ConvertService.toDateKey(new Date())
  //     })

  //     const batchStudentMovement = {
  //       key: this.ds.createId(),
  //       batchNew: {
  //         key: item.batch.key,
  //         name: item.batch.name
  //       },
  //       batchOld: null,
  //       create_date: new Date(),
  //       create_date_key: ConvertService.toDateKey(new Date()),
  //       create_by: item.create_by,
  //       page_key: ConvertService.pageKey(),
  //       term: previousTerm,
  //       studentKey: m.student.key,
  //     }

  //     batch.set(batchMovementRef.doc(batchStudentMovement.key), batchStudentMovement);
  //     batch.set(studentRef.doc(batchStudentMovement.studentKey).collection("student_batch_movement").doc(batchStudentMovement.key), batchStudentMovement);
  //     batch.set(batchLevelGradingRef.doc(batchGradingKey).collection("students").doc(m.key), m);
  //   })
  //   batch.set(movementRef, item)

  //   //OLD BATCH
  //   batch.update(batchRef, {
  //     name: `${item.name}.${term.code}`,
  //     success: true,
  //     promoted_date: new Date(),
  //     promoted_date_key: ConvertService.toDateKey(new Date()),
  //     promoted_by: item.create_by,
  //     term: previousTerm,
  //     status: batchStatusObj.close,
  //   })

  //   batch.commit().then(() => {
  //     this.process = false;
  //     this.selectedBatch = null;
  //     this.batchMembers = [];
  //     this.data = [];
  //     callback(true, null);
  //   }).catch(error => {
  //     alert(error)
  //     this.process = false;
  //     callback(false, error);
  //   });
  // }

  // @action
  // async promoteBatch(item: IPromoteBatch, callback) {
  //   this.process = true;
  //   const { term, level, create_by, previousTerm } = item;

  //   const currentTerm = {
  //     key: term.key,
  //     name: term.name,
  //     code: term.code,
  //   }

  //   const batchDocs = await this.ds.batchLevelRef().doc(this.selectedBatch.key).get().toPromise();
  //   const batchOld = MappingService.pushToObject(batchDocs);

  //   const studentDocs = await this.ds.batchLevelRef().doc(this.selectedBatch.key).collection("students").get().toPromise();
  //   const studentData = MappingService.pushToArray(studentDocs);
  //   const levelDoc = await this.ds.instituteTrainingLevelRef().doc(level.key).get().toPromise();
  //   const levelFee = MappingService.pushToObject(levelDoc);

  //   const batch = this.ds.batch();
  //   const movementRef = this.ds.promoteBatchLevelFireRef().doc(item.key);
  //   const batchRef = this.ds.batchLevelFireRef();
  //   const batchLevelGradingRef = this.ds.batchTrainingLevelGradingRef();
  //   const studentEnrollmentRef = this.ds.studentFireRef();

  //   // BATCH GRADING
  //   const batchGradingKey = this.ds.createId();
  //   batch.set(batchLevelGradingRef.doc(batchGradingKey), {
  //     ...batchOld,
  //     key: batchGradingKey,
  //     promote_by: create_by,
  //     promote_date: new Date(),
  //     promoted_date_key: ConvertService.toDateKey(new Date()),
  //     term: previousTerm,
  //     batch: {
  //       key: batchOld.key,
  //       name: batchOld.name,
  //     }
  //   })

  //   //NEW BATCH
  //   const newBatch: IBatch = {
  //     key: this.ds.createId(),
  //     name: item.name,
  //     description: item.description,
  //     min_student: item.min_student,
  //     max_student: item.max_student,
  //     shortName: null,
  //     status: batchStatusObj.active,
  //     create_date: new Date(),
  //     create_date_key: ConvertService.toDateKey(new Date()),
  //     create_by: item.create_by,
  //     page_key: ConvertService.pageKey(),
  //     term: currentTerm,
  //     level: level,
  //     shift: batchOld.shift,
  //     campus: batchOld.campus,
  //     session: batchOld.session,
  //   };
  //   batch.set(batchRef.doc(newBatch.key), newBatch);

  //   //OLD BATCH
  //   batch.set(movementRef, item)
  //   batch.update(batchRef.doc(this.selectedBatch.key), {
  //     //RESOLVE

  //     promoted_date: new Date(),
  //     promoted_date_key: ConvertService.toDateKey(new Date()),
  //     promoted_by: item.create_by,
  //     success: false,
  //     term: previousTerm,
  //     status: batchStatusObj.close,
  //   })

  //   studentData.forEach(m => {
  //     const enrollmentKey = this.ds.createId();
  //     const { student, program } = m;
  //     let studentProgram = null;
  //     if (program && program.admissionKey) {
  //       studentProgram = program;
  //     } else {
  //       studentProgram = student.program_academic
  //     }

  //     const enrollment: IEnrollment = {
  //       key: enrollmentKey,
  //       enrollmentOldKey: enrollmentKey,
  //       isFreshmen: false,
  //       admissionKey: studentProgram.admissionKey || m.admissionKey,
  //       create_date: item.create_date,
  //       create_by: item.create_by,
  //       status: recordStatus.active,
  //       page_key: ConvertService.pageKey(),
  //       enroll_status: enrollStatusObj.add,
  //       enrollment_type: enrollmentTypes.institute,
  //       enroll_date: item.create_date,
  //       enroll_date_key: ConvertService.dateKey(),
  //       student: MappingService.studentObj(student),
  //       subject: levelFee, //NO MAPPING
  //       fee: MappingService.feeObj(levelFee.fee),
  //       campus: batchOld.campus,
  //       shift: batchOld.shift,
  //       major: null,
  //       isStudentEnroll: true,
  //       courseCode: null,
  //       courseName: level.name,
  //       credits_attempted: null,
  //       credits_calculator: 0,
  //       credits_earned: 0,
  //       gradePoint: 0,
  //       gradeRef: null,
  //       isCompleted: false,
  //       active: true,
  //       isEnroll: true,
  //       included: true,
  //       isImport: false,
  //       isShow: true,
  //       labHours: null,
  //       lecHours: null,
  //       overall_grade: null,
  //       program: studentProgram,
  //       term: term,
  //       schedule: null,
  //       isApproval: true,
  //       isPaid: false,
  //       target_campus: ConvertService.toNull(batchOld.campus),
  //       target_term: academicYearObj(term),
  //       study_session: batchOld.shift,
  //       price: levelFee.fee.price,
  //       qty: 1,
  //       total: levelFee.fee.price * 1,
  //       scholarship: null,
  //       scholarshipRef: null,
  //       loan: null,
  //       prepaid: null,
  //       installment: null,
  //       grand_total: levelFee.fee.price * 1,
  //       batchOld: {
  //         key: batchOld.key,
  //         name: batchOld.name,
  //       },
  //       batchNew: {
  //         key: newBatch.key,
  //         name: newBatch.name
  //       },
  //       isPromoteBatch: true,
  //     };

  //     batch.set(batchRef.doc(newBatch.key).collection("students").doc(enrollment.key), enrollment);
  //     batch.set(studentEnrollmentRef.doc(student.key).collection("institute_enrollment").doc(enrollment.key), enrollment);

  //     //STUDENT GRADING
  //     batch.set(batchLevelGradingRef.doc(batchGradingKey).collection("students").doc(m.key), m);
  //   });

  //   batch.commit().then(() => {
  //     this.process = false;
  //     this.selectedBatch = null;
  //     this.batchMembers = [];
  //     this.data = [];
  //     callback(true, null);
  //   })
  //     .catch(error => {
  //       alert(error)
  //       this.process = false;
  //       callback(false, error);
  //     });
  // }

  // @action
  // async promoteAddStudentInvoiceBatch(item: IPromoteBatch, callback) {
  //   this.process = true;
  //   const { term, key, level } = item;
  //   const studentDocs = await this.ds.batchLevelRef().doc(this.selectedBatch.key).collection("students", ref => ref.where("student.puc_id", "==", "0115790")).get().toPromise();
  //   // const levelDoc = await this.ds.instituteTrainingLevelRef().doc(level.key).get().toPromise();
  //   const levelDoc = await this.ds.instituteTrainingLevelRef().doc("mR1maLV25RecFmxCCTu3").get().toPromise(); //0688963, 0691289
  //   const levelFee = MappingService.pushToObject(levelDoc);
  //   const batch = this.ds.batch();
  //   const movementRef = this.ds.promoteBatchLevelFireRef().doc(item.key);
  //   const batchRef = this.ds.batchLevelFireRef().doc(this.selectedBatch.key);
  //   const studentEnrollmentRef = this.ds.studentFireRef();
  //   studentDocs.forEach(doc => {
  //     const enrollmentKey = this.ds.createId();
  //     const { student, campus, study_session, program } = doc.data();
  //     const enrollment: any = {
  //       key: enrollmentKey,
  //       enrollmentOldKey: key,
  //       isFreshmen: false,
  //       admissionKey: program.admissionKey,
  //       create_date: item.create_date,
  //       create_by: item.create_by,
  //       status: recordStatus.active,
  //       page_key: ConvertService.pageKey(),
  //       enroll_status: enrollStatusObj.add,
  //       enrollment_type: enrollmentTypes.institute,
  //       enroll_date: item.create_date,
  //       enroll_date_key: ConvertService.dateKey(),
  //       student: MappingService.studentObj(student),
  //       subject: levelFee, //NO MAPPING
  //       fee: MappingService.feeObj(levelFee.fee),
  //       campus: campus,
  //       shift: study_session,
  //       major: null,
  //       isStudentEnroll: true,
  //       courseCode: null,
  //       courseName: level.name,
  //       credits_attempted: null,
  //       credits_calculator: 0,
  //       credits_earned: 0,
  //       gradePoint: 0,
  //       gradeRef: null,
  //       isCompleted: false,
  //       active: true,
  //       isEnroll: true,
  //       included: true,
  //       isImport: false,
  //       isShow: true,
  //       labHours: null,
  //       lecHours: null,
  //       overall_grade: null,
  //       program: program,
  //       term: term,
  //       schedule: null,
  //       isApproval: true,
  //       isPaid: false,
  //       target_campus: campus,
  //       target_term: academicYearObj(term),
  //       study_session: study_session,
  //       price: levelFee.fee.price,
  //       qty: 1,
  //       total: levelFee.fee.price * 1,
  //       scholarship: null,
  //       scholarshipRef: null,
  //       loan: null,
  //       prepaid: null,
  //       installment: null,
  //       grand_total: levelFee.fee.price * 1,
  //     };
  //     batch.set(studentEnrollmentRef.doc(student.key).collection("institute_enrollment").doc(enrollment.key), enrollment)
  //   });

  //   // batch.set(movementRef, item)
  //   // batch.update(batchRef, {
  //   //   level: MappingService.levelObj(item.level),
  //   //   max_student: item.max_student,
  //   //   min_student: item.min_student,
  //   //   name: item.name,
  //   //   description: item.description,
  //   //   update_date: item.create_date,
  //   //   update_by: item.create_by,
  //   //   success:false,
  //   // })

  //   // batch.commit().then(() => {
  //   //   this.process = false;
  //   //   this.selectedBatch = null;
  //   //   this.batchMembers = [];
  //   //   this.data = [];
  //   //   callback(true, null);
  //   // })
  //   //   .catch(error => {
  //   //     alert(error)
  //   //     this.process = false;
  //   //     callback(false, error);
  //   //   });
  // }

  @action
  deleteBatch(callback) {
    this.process = true;
    this.ds.batchLevelRef().doc(this.selectedBatch.key).delete().then(() => {
      this.process = false;
      this.selectedBatch = null;
      callback(true, null);
    })
      .catch(error => {
        alert(error)
        this.process = false;
        callback(false, error);
      });
  }

  @action
  addSchedule(item: ICourseSchedule, secondItem: ICourseSchedule, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const scheduleRef = this.ds.academicYearFireRef().doc(item.academicYear.key).collection("schedules");
    const movementRef = this.ds.batchLevelFireRef().doc(this.selectedBatch.key).collection("schedules");
    batch.set(scheduleRef.doc(item.key), item, { merge: true });
    batch.set(movementRef.doc(item.key), item, { merge: true });

    if (secondItem) {
      batch.set(scheduleRef.doc(secondItem.key), secondItem, { merge: true });
      batch.set(movementRef.doc(secondItem.key), secondItem, { merge: true });
    }

    batch.commit()
      .then(() => {
        this.process = false;
        callback(true, null);
      })
      .catch(error => {
        this.process = false;
        callback(false, error);
      });
  }

  @action
  editSchedule(item: ICourseSchedule, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const scheduleRef = this.ds.academicYearFireRef().doc(item.academicYear.key).collection("schedules");
    const movementRef = this.ds.batchLevelFireRef().doc(this.selectedBatch.key).collection("schedules");
    batch.update(scheduleRef.doc(item.key), item);
    batch.update(movementRef.doc(item.key), item);
    batch.commit()
      .then(() => {
        this.process = false;
        callback(true, null);
      })
      .catch(error => {
        this.process = false;
        callback(false, error);
      });
  }

  @action
  deleteSchedule(item: ICourseSchedule, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const scheduleRef = this.ds.academicYearFireRef().doc(item.academicYear.key).collection("schedules");
    const movementRef = this.ds.batchLevelFireRef().doc(this.selectedBatch.key).collection("schedules");
    batch.delete(scheduleRef.doc(item.key));
    batch.delete(movementRef.doc(item.key));
    batch.commit()
      .then(() => {
        this.process = false;
        callback(true, null);
      })
      .catch(error => {
        this.process = false;
        callback(false, error);
      });
  }

  @action
  removeStudent(item: any, user: any, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const studentRef = this.ds.instituteFailFireRef().doc(item.key);
    const studentBatchRef = this.ds.batchLevelFireRef().doc(this.selectedBatch.key).collection("students");
    batch.delete(studentBatchRef.doc(item.key));
    batch.set(studentRef, {
      ...item,
      remove_from_batch_by: MappingService.userObj(user),
      remove_from_batch_date: new Date(),
    });
    batch.commit()
      .then(() => {
        this.process = false;
        callback(true, null);
      })
      .catch(error => {
        this.process = false;
        callback(false, error);
      });
  }

  @action
  async removeStudentNAdmissionFromBatchEnroll(item: any, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const { student, program_academic, key, batchKey, academicYear } = item;

    const batchRef = this.ds.batchLevelFireRef().doc(batchKey);
    const removeStudentFromBatchRef = this.ds.removeStudentFromBatchFireRef();
    const studentRef = this.ds.studentFireRef();
    const academicYearRef = this.ds.academicYearFireRef();

    const admissionRef = this.ds.admissionDocFireRef(key);

    const batchLevelData = await this.ds.batchLevelRef().doc(batchKey).collection("students", ref => ref.where("student.key", "==", student.key)).get().toPromise();;
    const batchLevelDoc = MappingService.pushToArray(batchLevelData);

    if (batchLevelDoc && batchLevelDoc.length > 0) {
      batchLevelDoc.forEach(doc => {
        batch.delete(batchRef.collection("students").doc(doc.key));

        batch.delete(academicYearRef.doc(academicYear.key).collection("enrollments").doc(doc.key))
        batch.delete(academicYearRef.doc(academicYear.key).collection("campus").doc(doc.campus.key).collection("enrollments").doc(doc.key))
      })
    }

    batch.set(removeStudentFromBatchRef.doc(item.key), item);
    batch.delete(admissionRef)
    batch.update(batchRef, { total_enroll: firebase.firestore.FieldValue.increment(-1) });

    batch.update(studentRef.doc(student.key), {
      [program_academic.key]: null
    })

    batch.commit()
      .then(() => {
        this.process = false;
        callback(true, null);
      })
      .catch(error => {
        this.process = false;
        callback(false, error);
      });
  }

  @action
  async removeStudentNAdmissionFromBatch(item: any, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const batchRef = this.ds.batchLevelFireRef().doc(this.selectedBatch.key);
    const removeStudentFromBatchRef = this.ds.removeStudentFromBatchFireRef();
    const studentRef = this.ds.studentFireRef();

    const { student, program, admissionKey } = item;
    const admissionRef = this.ds.admissionDocFireRef(admissionKey);

    const batchLevelData = await this.ds.batchLevelRef().doc(this.selectedBatch.key).collection("students", ref => ref.where("student.key", "==", student.key)).get().toPromise();;
    const batchLevelDoc = MappingService.pushToArray(batchLevelData);

    if (batchLevelDoc && batchLevelDoc.length > 0) {
      batchLevelDoc.forEach(doc => {
        batch.delete(batchRef.collection("students").doc(doc.key));
      })
    }

    batch.set(removeStudentFromBatchRef.doc(item.key), item);
    batch.delete(admissionRef)
    batch.update(batchRef, { total_enroll: firebase.firestore.FieldValue.increment(-1) });

    batch.update(studentRef.doc(student.key), {
      [program.key]: null
    })

    batch.commit()
      .then(() => {
        this.process = false;
        callback(true, null);
      })
      .catch(error => {
        this.process = false;
        callback(false, error);
      });
  }

  @action
  async removeStudentFromBatch(item: any, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const batchRef = this.ds.batchLevelFireRef().doc(this.selectedBatch.key);
    const removeStudentFromBatchRef = this.ds.removeStudentFromBatchFireRef();
    const studentRef = this.ds.studentFireRef();
    const academicYearRef = this.ds.academicYearFireRef();

    const { student, program, admissionKey, campus } = item;

    const admissionRef = this.ds.admissionDocFireRef(admissionKey);

    const batchLevelData = await this.ds.batchLevelRef().doc(this.selectedBatch.key).collection("students", ref => ref.where("student.key", "==", student.key)).get().toPromise();;
    const batchLevelDoc = MappingService.pushToArray(batchLevelData);

    if (batchLevelDoc && batchLevelDoc.length > 0) {
      batchLevelDoc.forEach(doc => {
        batch.delete(batchRef.collection("students").doc(doc.key));
      })
    }

    batch.set(removeStudentFromBatchRef.doc(item.key), item);

    batch.update(academicYearRef.doc(this.selectedAcademicYear.key).collection("admission").doc(admissionKey), {
      batchNew: null,
      batchRef: null,
      batchKey: null,
      isHaveBatch: false,
    })

    batch.update(academicYearRef.doc(this.selectedAcademicYear.key).collection("campus").doc(campus.key).collection("admission").doc(admissionKey), {
      batchNew: null,
      batchRef: null,
      batchKey: null,
      isHaveBatch: false,
    })

    batch.update(admissionRef, {
      batchNew: null,
      batchRef: null,
      batchKey: null,
      isHaveBatch: false,
    })

    batch.update(batchRef, { total_enroll: firebase.firestore.FieldValue.increment(-1) });

    batch.update(studentRef.doc(student.key), {
      [program.key]: null
    })

    batch.commit()
      .then(() => {
        this.process = false;
        callback(true, null);
      })
      .catch(error => {
        this.process = false;
        callback(false, error);
      });
  }

  @action
  async addFailStudent(item: any, user: any, instituteTerm: any, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const { program, target_campus, study_session, group, key } = item;
    const { level } = this.selectedBatch
    const levelDoc = await this.ds.instituteTrainingLevelRef().doc(level.key).get().toPromise();
    const levelFee = MappingService.pushToObject(levelDoc);

    const enrollmentInstituteRef = this.ds.studentEnrollInstituteFireRef();
    const instituteNoScheduleRef = this.ds.studentInstituteNoScheduleFireRef();
    const batchLevelRef = this.ds.batchLevelFireRef();
    const studentRef = this.ds.studentFireRef();
    const { admissionKey } = program;
    const date = new Date();
    const create_by = MappingService.userObj(user);
    const campus = MappingService.campusObj(user.campus);

    const student = MappingService.studentObj(item.student);
    const term = academicYearObj(instituteTerm);
    const targetCampus = MappingService.campusObj(target_campus);
    const shift = MappingService.shiftObj(study_session);
    const enrollmentKey = this.ds.createId();
    const enrollment: IEnrollment = {
      key: enrollmentKey,
      enrollmentOldKey: enrollmentKey,
      isFreshmen: false,
      admissionKey: admissionKey,
      create_date: date,
      create_by: create_by,
      status: recordStatus.active,
      page_key: ConvertService.pageKey(),
      enroll_status: enrollStatusObj.add,
      enrollment_type: enrollmentTypes.institute,
      enroll_date: date,
      enroll_date_key: ConvertService.dateKey(),
      student: student,
      subject: levelFee, //NO MAPPING
      fee: MappingService.feeObj(levelFee.fee),
      campus: campus,
      shift: shift,
      major: null,
      isStudentEnroll: false,
      courseCode: null,
      courseName: level.name,
      credits_attempted: null,
      credits_calculator: 0,
      credits_earned: 0,
      gradePoint: 0,
      gradeRef: null,
      isCompleted: false,
      active: true,
      isEnroll: false,
      included: true,
      isImport: false,
      isShow: true,
      labHours: null,
      lecHours: null,
      overall_grade: null,
      program: program,
      term: term,
      schedule: null,
      isApproval: true,
      isPaid: false,
      target_campus: targetCampus,
      target_term: term,
      study_session: shift,
      price: levelFee.fee.price,
      qty: 1,
      total: levelFee.fee.price * 1,
      scholarship: null,
      scholarshipRef: null,
      loan: null,
      prepaid: null,
      installment: null,
      grand_total: levelFee.fee.price * 1,
      institute_no_schedule: instituteNoScheduleObj.fail,
      is_no_schedule: true,
    };

    batch.set(enrollmentInstituteRef.doc(enrollment.key), enrollment);
    batch.set(instituteNoScheduleRef.doc(enrollment.key), enrollment);
    const instituteKey = this.selectedInstitute.key;
    batch.update(studentRef.doc(student.key), {
      [instituteKey]: null,
    });

    // TESTING DELETE BATCH MEMBER
    // batch.set(studentRef.doc(student.key).collection("batch_member").doc(item.key), item);
    batch.delete(batchLevelRef.doc(group.key).collection("students").doc(key));

    batch.commit().then(() => {
      this.process = false;
      callback(true, null);
    })
      .catch(error => {
        callback(false, error);
        this.process = false;
      });
  }


  @action
  async addComingStudentBatch(enrollments: Array<any>, user, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const date = new Date();
    const create_by = MappingService.userObj(user);
    const batchRef = this.ds.batchLevelFireRef().doc(this.selectedBatch.key).collection("students");
    const studentRef = this.ds.studentFireRef();
    const addBatchMovementRef = this.ds.batchLevelMovementFireRef();
    const batchStudentMovementRef = this.ds.studentBatchMovementFireRef();

    enrollments.forEach(async m => {
      const { key, program, student, target_term, target_campus, subject, study_session } = m;
      const institute = program.category;
      batch.update(studentRef.doc(student.key), {
        [institute.key]: MappingService.batchObj(this.selectedBatch)
      })
      batch.set(batchRef.doc(key), {
        ...m,
        isCompleted: true,
        add_batch_by: create_by,
        add_batch_date: date,
        add_batch_date_key: ConvertService.toDateKey(date)
      });
      batch.set(addBatchMovementRef.doc(key), {
        student: student,
        target_term: target_term,
        target_campus: target_campus,
        key: key,
        batch: MappingService.batchObj(this.selectedBatch),
        institute: institute,
        add_batch_by: create_by,
        add_batch_date: date,
        add_batch_date_key: ConvertService.toDateKey(date),
        testing_result: subject,
        study_session: study_session
      })

      const batchStudentMovement = {
        key: this.ds.createId(),
        batchNew: {
          key: this.selectedBatch.key,
          name: this.selectedBatch.name
        },
        batchOld: null,
        create_date: new Date(),
        create_date_key: ConvertService.toDateKey(new Date()),
        create_by: create_by,
        page_key: ConvertService.pageKey(),
        term: {
          key: target_term.key,
          name: target_term.name,
          code: target_term.code,
        },
        studentKey: student.key,
      }

      batch.set(batchStudentMovementRef.doc(batchStudentMovement.key), batchStudentMovement);
      batch.set(studentRef.doc(batchStudentMovement.studentKey).collection("student_batch_movement").doc(batchStudentMovement.key), batchStudentMovement);

      batch.delete(this.ds.instituteNoScheduleStudentFireRef().doc(key))
    })
    batch.commit().then(() => {
      this.process = false;
      callback(true, null);
    }).catch(error => {
      this.process = false;
      callback(false, error);
    });
  }

  async margeBatch(oldBatch: any, newBatch: any, user, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const date = new Date();
    const create_by = MappingService.userObj(user);
    const studentInBatchDoc = await this.ds.batchLevelRef().doc(oldBatch.key).collection("students").get().toPromise();
    const newBatchRef = this.ds.batchLevelFireRef().doc(newBatch.key).collection("students");
    const oldBatchRef = this.ds.batchLevelFireRef().doc(oldBatch.key).collection("students");
    const studentRef = this.ds.studentFireRef();
    const batchStudentMovementRef = this.ds.studentBatchMovementFireRef();

    let instituteKey = "DBrSzLY3UQ2kEgF50nfU";
    studentInBatchDoc.forEach(item => {
      const enrollment = item.data();
      batch.set(newBatchRef.doc(item.id), enrollment)
      const { student, program, target_term } = enrollment;
      batch.update(studentRef.doc(student.key), {
        [instituteKey]: MappingService.batchObj(newBatch),
        add_batch_by: create_by,
        add_batch_date: date,
        add_batch_date_key: ConvertService.toDateKey(date),
      })

      const batchStudentMovement = {
        key: this.ds.createId(),
        batchNew: {
          key: newBatch.key,
          name: newBatch.name
        },
        batchOld: null,
        create_date: new Date(),
        create_date_key: ConvertService.toDateKey(new Date()),
        create_by: create_by,
        page_key: ConvertService.pageKey(),
        term: {
          key: target_term.key,
          name: target_term.name,
          code: target_term.code,
        },
        studentKey: student.key,
      }

      batch.set(batchStudentMovementRef.doc(batchStudentMovement.key), batchStudentMovement);
      batch.set(studentRef.doc(batchStudentMovement.studentKey).collection("student_batch_movement").doc(batchStudentMovement.key), batchStudentMovement);

      batch.delete(oldBatchRef.doc(item.id))
    })

    batch.commit().then(() => {
      this.process = false;
      callback(true, null);
    }).catch(error => {
      this.process = false;
      callback(false, error);
    });

  }

  @action
  async addNewStudent(enrollments: Array<any>, env: any, callback) {
    this.process = true;
    const batch = this.ds.batch();

    const { user } = env;
    const selectedYear = this.selectedAcademicYear;
    const create_by = MappingService.userObj(user);
    const batchRef = this.ds.batchLevelFireRef().doc(this.selectedBatch.key);
    const studentRef = this.ds.studentFireRef();
    const batchStudentMovementRef = this.ds.studentBatchMovementFireRef();
    const academicYearRef = this.ds.academicYearFireRef();

    enrollments.forEach(async m => {
      const { student, program_academic } = m;

      let enrollmentKey = this.ds.createId();
      const date = new Date();
      const admissionKey = program_academic.admissionKey;

      const item: IEnrollment = {
        key: enrollmentKey,
        enrollmentOldKey: enrollmentKey,
        isFreshmen: false,
        admissionKey: admissionKey,
        create_date: date,
        create_by: create_by,
        status: recordStatus.active,
        page_key: ConvertService.pageKey(),
        enroll_status: enrollStatusObj.add,
        enrollment_type: enrollmentTypes.PSIS,
        enroll_date: date,
        enroll_date_key: ConvertService.dateKey(),
        student: student,
        subject: this.selectedLevel,
        fee: null,
        campus: campusObj(this.selectedCampus),
        shift: shiftObj(this.selectedShift),
        major: null,
        isStudentEnroll: false,
        courseCode: null,
        courseName: this.selectedLevel.name,
        credits_attempted: null,
        credits_calculator: 0,
        credits_earned: 0,
        gradePoint: 0,
        gradeRef: null,
        isCompleted: false,
        active: true,
        isEnroll: true,
        included: true,
        isImport: false,
        isShow: true,
        labHours: null,
        lecHours: null,
        overall_grade: null,
        program: program_academic,
        academicYear: this.selectedAcademicYear,
        schedule: null,
        isApproval: true,
        isPaid: false,
        price: null,
        qty: 1,
        total: null,
        scholarship: null,
        scholarshipRef: null,
        loan: null,
        prepaid: null,
        installment: null,
        grand_total: null,
        batchNew: {
          key: this.selectedBatch.key,
          name: this.selectedBatch.name
        },
        isPromoteBatch: false,
      };

      batch.update(studentRef.doc(student.key), {
        [program_academic.key]: MappingService.batchObj(this.selectedBatch)
      })

      batch.set(batchRef.collection("students").doc(item.key), item);

      const batchStudentMovement = {
        key: this.ds.createId(),
        batchNew: {
          key: this.selectedBatch.key,
          name: this.selectedBatch.name
        },
        batchRef: this.ds.batchLevelDocRef(this.selectedBatch.key).ref,
        batchKey: this.selectedBatch.key,
        batchOld: null,
        create_date: new Date(),
        create_date_key: ConvertService.toDateKey(new Date()),
        create_by: create_by,
        page_key: ConvertService.pageKey(),
        academicYear: {
          key: selectedYear.key,
          name: selectedYear.name,
        },
        academicYearRef: this.ds.academicYearDocRef(selectedYear.key).ref,
        academicYearKey: selectedYear.key,
        studentKey: student.key,
      }

      batch.set(batchStudentMovementRef.doc(batchStudentMovement.key), batchStudentMovement);
      batch.set(studentRef.doc(batchStudentMovement.studentKey).collection("student_batch_movement").doc(batchStudentMovement.key), batchStudentMovement);

      batch.update(academicYearRef.doc(selectedYear.key).collection("admission").doc(admissionKey), {
        batchNew: {
          key: this.selectedBatch.key,
          name: this.selectedBatch.name
        },
        batchRef: this.ds.batchLevelDocRef(this.selectedBatch.key).ref,
        batchKey: this.selectedBatch.key,
        isHaveBatch: true,
      })

      batch.update(academicYearRef.doc(selectedYear.key).collection("campus").doc(m.campus.key).collection("admission").doc(admissionKey), {
        batchNew: {
          key: this.selectedBatch.key,
          name: this.selectedBatch.name
        },
        batchRef: this.ds.batchLevelDocRef(this.selectedBatch.key).ref,
        batchKey: this.selectedBatch.key,
        isHaveBatch: true,
      })

      batch.update(this.ds.admissionDocFireRef(admissionKey), {
        batchNew: {
          key: this.selectedBatch.key,
          name: this.selectedBatch.name
        },
        batchRef: this.ds.batchLevelDocRef(this.selectedBatch.key).ref,
        batchKey: this.selectedBatch.key,
        isHaveBatch: true,
      })
    })

    batch.update(batchRef, { total_enroll: firebase.firestore.FieldValue.increment(enrollments.length) });

    batch.commit().then(() => {
      this.process = false;
      callback(true, null);
    }).catch(error => {
      this.process = false;
      callback(false, error);
    });
  }

  @action
  async addStudentToClass(students: Array<any>, user: any, callback) {
    this.process = true;
    const batch = this.ds.batch();

    const ref = this.ds.import_student_to_classFireRef();
    const { school } = user
    students.forEach(async m => {
      const { ID, Class } = m;

      const item: IImportStudentToClass = {
        key: this.ds.createId(),
        create_date: new Date(),
        create_by: userObj(user),
        status: recordStatus.active,
        page_key: ConvertService.pageKey(),

        ID,
        Class,
        trainingGrade: academicTrainingFeeObj(this.selectedLevel),
        trainingBatch: MappingService.batchObj(this.selectedBatch),
        academicYear: academicYearObj(this.selectedAcademicYear),
        campus: campusObj(this.selectedCampus),
        campusKey: this.selectedCampus.key,
        schoolKey: school.key,
        school: school,
        shift: shiftObj(this.selectedShift),
        trainingProgram: academicTrainingProgramObj(this.selectedProgram),
        tran: false,
      };

      batch.set(ref.doc(item.key), item);
    })

    batch.commit().then(() => {
      this.process = false;
      callback(true, null);
    }).catch(error => {
      this.process = false;
      callback(false, error);
    });
  }

  @action
  removeBatchStudent(enrollment: any, user, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const batchRef = this.ds.batchLevelFireRef().doc(this.selectedBatch.key).collection("students");
    const instituteEnrollmentRef = this.ds.termFireRef().doc(this.selectedTerm.key).collection("institute_enrollment");
    const studentRef = this.ds.studentFireRef();
    const { admissionKey, student, key } = enrollment;
    batch.update(instituteEnrollmentRef.doc(admissionKey), {
      isCompleted: false,
      remove_batch_by: MappingService.userObj(user),
      remove_batch_date: new Date(),
      remove_batch_date_key: ConvertService.toDateKey(new Date())
    });
    const instituteKey = this.selectedInstitute.key;
    batch.update(studentRef.doc(student.key), {
      [instituteKey]: null,
    });
    batch.delete(batchRef.doc(key));
    batch.commit().then(() => {
      this.process = false;
      callback(true, null);
    }).catch(error => {
      this.process = false;
      callback(false, error);
    });
  }

  @action
  async checkExistMonthGradeSheet(termKey, scheduleKey, monthKey, gradeSheetTypeKey) {
    const doc = await this.ds.termRef().doc(termKey)
      .collection('schedules').doc(scheduleKey)
      .collection("grade_sheets", ref => ref
        .where("monthKey", "==", monthKey)
        .where("gradeSheetType.key", "==", gradeSheetTypeKey)
      ).get().toPromise();
    return doc.empty ? false : true;
  }

  @action
  async addMonthlyGradeSheet(item: IMonthlyGrade, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const transcriptRef = this.ds.termFireRef().doc(item.termKey).collection('schedules').doc(item.scheduleKey).collection("grade_sheets").doc(item.key)
    const movementRef = this.ds.gradeSheetMovementRef().doc(item.key)
    batch.set(movementRef, item, { merge: true })
    batch.set(transcriptRef, item, { merge: true })
    batch.commit().then(() => {
      callback(true, null);
    }).catch((error) => {
      callback(false, error)
    }).finally(() => {
      this.process = false;
    })
  }

  @action
  async deleteCurrentTestingPermission() {
    try {
      const permissions = pushToArray(await this.afs.collection('stores/ngs/campus/8CUqw3o6xIOzQlYKuVUb/permissions',
        ref => ref.where('reason', 'in', ['Testing auxswot', 'Testing '])).get().toPromise());

      // console.log(permissions)
      return;

      for await (const [index, item] of permissions.entries()) {
        console.log(`deleting ${index + 1} of ${permissions.length}`);
        await this.afs.collection('stores').doc('ngs').collection('permissions').doc(item.key).delete();
        await this.afs.collection('students').doc(item.studentKey).collection('permissions').doc(item.key).delete();
        await this.afs.collection('stores').doc('ngs').collection('campus').doc('8CUqw3o6xIOzQlYKuVUb').collection('permissions').doc(item.key).delete();
      }

      console.log('all done!')
    } catch (error) {
      console.log(error);
    }
  }

  @action
  async deleteTestingData() {
    // this.deleteCurrentTestingPermission();
    return;

    // const schoolKey: string = 'ngs-preakleap';
    // const campusKey: string = '8CUqw3o6xIOzQlYKuVUb';
    // const admissionKey: string = 'WFH15mVOLSG8xgkrAPfU';
    // const studentKeys: Array<string> = ['bmc71gxmMWlfsL0o21C7'];

    // const schoolKey: string = 'ngs_peam_chikang';
    // const campusKey: string = 'yKwWdGgRCrkRXYzLS9Tu';
    // const studentKeys: Array<string> = ['DacHGmPgM44oQXru23Jp', 'VfD3bds7Gub7DOOPDNgh',
    //   'IKjasyhdgonTRyjp2GXD', 'NYMkM6XiXAgrN9p6t71P', 'Du1AyUDIxbZusHldbt6L', 'Q9GcuYqryby5ToZPNzq7'];

    const studentKeys: Array<string> = ['A3mv9VZzVGNbJHcZFy4O', 'ASELr0xv5U4rwv58H6xg',
      'NWzwI5vRJEKDZ4scy40s', '38geJdvnhVOdjB2GwTKD', 'iDxTlE4s7s6BH5cBcs44'];
    const schoolKey: string = 'sovannaphumi_school';

    const dateKeys: Array<number> = [20220628, 20220629];
    const monthKeys: Array<number> = [202206];

    try {
      Promise.all(studentKeys.map(async (studentKey) => {
        const admission = pushToArray(await this.afs.collection('academics_major_admission', ref => ref.where('studentKey', '==', studentKey)).get().toPromise());
        const admissionKey = admission[0]?.key;
        if (!admissionKey) return null;

        // ----- delete attendance docs -----

        // delete time-attendance from studentKey
        const timeAttendancesFromStudent = pushToArray(await this.afs.collection('students').doc(studentKey)
          .collection('time_attendance', ref => ref.where('create_date_key', 'in', dateKeys)).get().toPromise());
        // delete attendance-movement from studentKey
        const attendanceMovementsFromStudent = pushToArray(await this.afs.collection('students').doc(studentKey)
          .collection('student_attendance_movement', ref => ref.where('create_date_key', 'in', dateKeys)).get().toPromise());
        // delete time-attendance from schoolKey
        const timeAttendancesFromSchool = pushToArray(await this.afs.collection('stores').doc(schoolKey)
          .collection('time_attendance', ref => ref.where('studentKey', '==', studentKey).where('create_date_key', 'in', dateKeys)).get().toPromise());
        // delete attendance-movement from schoolKey
        const attendanceMovementsFromSchool = pushToArray(await this.afs.collection('stores').doc(schoolKey)
          .collection('student_attendance_movement', ref => ref.where('studentKey', '==', studentKey).where('create_date_key', 'in', dateKeys)).get().toPromise());

        // console.log('timeAttendancesFromStudent', timeAttendancesFromStudent)
        // console.log('attendanceMovementsFromStudent', attendanceMovementsFromStudent)
        // console.log('timeAttendancesFromSchool', timeAttendancesFromSchool)
        // console.log('attendanceMovementsFromSchool', attendanceMovementsFromSchool)

        Promise.all(timeAttendancesFromStudent.map(async item => {
          await this.afs.collection('students').doc(studentKey).collection('time_attendance').doc(item.key).delete();
          return;
        }));

        Promise.all(attendanceMovementsFromStudent.map(async item => {
          await this.afs.collection('students').doc(studentKey).collection('student_attendance_movement').doc(item.key).delete();
          return;
        }));

        Promise.all(timeAttendancesFromSchool.map(async item => {
          await this.afs.collection('stores').doc(schoolKey).collection('time_attendance').doc(item.key).delete();
          return;
        }));

        Promise.all(attendanceMovementsFromSchool.map(async item => {
          await this.afs.collection('stores').doc(schoolKey).collection('student_attendance_movement').doc(item.key).delete();
          return;
        }));

        // // ----- delete attendance stats doc from admission -----

        // delete attendance-movement from schoolKey
        const admission_attendanceStatistics = pushToArray(await this.afs.collection('academics_major_admission').doc(admissionKey)
          .collection('admission_attendance_statistic', ref => ref.where('create_date_month_key', 'in', monthKeys)).get().toPromise());

        // delete attendance-movement from schoolKey
        const student_attendanceStatistics = pushToArray(await this.afs.collection('academics_major_admission').doc(admissionKey)
          .collection('student_attendance_statistic', ref => ref.where('key', 'in', monthKeys)).get().toPromise());

        Promise.all(admission_attendanceStatistics.map(async item => {
          await this.afs.collection('academics_major_admission').doc(admissionKey)
            .collection('admission_attendance_statistic').doc(item.key).delete();
          return;
        }));

        Promise.all(student_attendanceStatistics.map(async item => {
          await this.afs.collection('academics_major_admission').doc(admissionKey)
            .collection('student_attendance_statistic').doc(item.key.toString()).delete();
          return;
        }));

        // ----- delete permission doc from school -----

        // // delete attendance-movement from schoolKey
        // const student_permissions = pushToArray(await this.afs.collection('students').doc(studentKey)
        //   .collection('permissions').get().toPromise());

        // // delete attendance-movement from schoolKey
        // const student_permission_school = pushToArray(await this.afs.collection('stores').doc(schoolKey)
        //   .collection('campus').doc(campusKey).collection('permissions', ref => ref.where('studentKey', '==', studentKey)).get().toPromise());

        // Promise.all(student_permissions.map(async item => {
        //   await this.afs.collection('students').doc(studentKey)
        //     .collection('permissions').doc(item.key).delete();
        //   return;
        // }));

        // Promise.all(student_permission_school.map(async item => {
        //   await this.afs.collection('stores').doc(schoolKey).collection('campus').doc(campusKey)
        //     .collection('permissions').doc(item.key).delete();
        //   return;
        // }));

        // console.log('deleted testing data...')
      }))
    } catch (error) {
      console.log(error)
    }
  }

  @action
  async getCurrentStudentAttendanceRecords(remarkName: string, currentDateKey: number, schoolKey: string) {
    try {
      const sysConfig = pushToObject(await this.afs.collection('stores').doc(schoolKey).collection('sys_option').doc('general').get().toPromise());
      const { attendanceA, attendanceAP, attendanceL, attendanceM, attendanceP } = sysConfig;
      let criteriaKeys = [];

      if (remarkName === 'A') criteriaKeys.push(attendanceA.key);
      else if (remarkName === 'P') criteriaKeys.push(attendanceAP.key);

      let current_admissionAttendanceStatistic = pushToArray(await this.afs.collectionGroup('admission_attendance_statistic', ref => {
        let condition = ref.where('remark.key', 'in', criteriaKeys)
        condition = condition.where('create_date_key', '==', currentDateKey)
        condition = condition.limit(5);
        return condition;
      }).get().toPromise());

      current_admissionAttendanceStatistic = await Promise.all(current_admissionAttendanceStatistic.map(async (item) => {
        let studentInfo = pushToArray(await this.afs.collection('students', ref => ref.where('program_academic.admissionKey', '==', item.admissionKey)).get().toPromise());
        studentInfo = studentInfo.length > 0 ? studentInfo[0] : null;
        return { ...item, studentInfo }
      }));
      return current_admissionAttendanceStatistic;
    } catch (error) {
      console.log(error);
    }
  }

  @action
  async getStudentInfoAndCurrentMovements(studentKey: string, dateKey: number, dateMonthKey: number) {
    try {
      const studentInfo = pushToObject(await this.afs.collection('students').doc(studentKey).get().toPromise());
      const movements = pushToArray(await this.afs.collection('students').doc(studentKey)
        .collection('student_attendance_movement', ref => ref.where('create_date_key', '==', dateKey)).get().toPromise());
      const current_attendanceStat = pushToObject(await this.afs.collection('academics_major_admission')
        .doc(studentInfo.program_academic.admissionKey).collection('student_attendance_statistic').doc(dateMonthKey + '').get().toPromise());
      let all_attendanceStat = pushToArray(await this.afs.collection('academics_major_admission')
        .doc(studentInfo.program_academic.admissionKey).collection('student_attendance_statistic', ref => ref.orderBy('key')).get().toPromise());
      all_attendanceStat = all_attendanceStat.filter((item) => item.key <= dateMonthKey);

      const studentAttendanceStat_currentMonth = current_attendanceStat;
      const studentAttendanceStat_allMonth = { totalA: 0, totalAL: 0, totalAP: 0, totalL: 0, totalM: 0, totalP: 0 };
      // const studentAttendanceStats_prevMonths = all_attendanceStat.filter((item) => item.date !== dateMonthKey);

      all_attendanceStat.forEach((item) => {
        studentAttendanceStat_allMonth.totalA += item.totalA;
        studentAttendanceStat_allMonth.totalAL += item.totalAL;
        studentAttendanceStat_allMonth.totalAP += item.totalAP;
        studentAttendanceStat_allMonth.totalL += item.totalL;
        studentAttendanceStat_allMonth.totalM += item.totalM;
        studentAttendanceStat_allMonth.totalP += item.totalP;
      });

      return { ...studentInfo, movements, studentAttendanceStat_currentMonth, studentAttendanceStat_allMonth };
    } catch (error) {
      console.log(error)
    }
  }

  @action
  async updateNoteToTimeAttendanceOrAttendanceMovement(note: string, studentKey: string, timeAttendanceKey: string, attendanceMovementKey: string, callback: (success, error) => void) {
    try {
      if (timeAttendanceKey && !attendanceMovementKey) {
        await this.ds.studentsRef().doc(studentKey).collection('time_attendance').doc(timeAttendanceKey).update({ note: note });
        await this.ds.storeRef().collection('time_attendance').doc(timeAttendanceKey).update({ note: note });
      } else if (timeAttendanceKey && attendanceMovementKey) {
        await this.ds.studentsRef().doc(studentKey).collection('student_attendance_movement').doc(attendanceMovementKey).update({ note: note });
        await this.ds.storeRef().collection('student_attendance_movement').doc(attendanceMovementKey).update({ note: note });
      }

      callback && callback(true, null);
    } catch (error) {
      console.log(error)
      callback && callback(false, error);
    }
  }

  @action
  async fetchListingAttendanceMethod() {
    try {
      const ref = this.afs.collection('stores').doc(SCHOOL.key).collection('academic_environment').doc('academic_environment');
      const data = pushToObject(await ref.get().toPromise());
      return data?.attendanceRegistratonMethod;
    } catch (error) {
      console.log(error)
    }
  }

  @action
  async updateAttendanceRegistratonMethod(method: any, callback?: (success, error) => void) {
    try {
      const ref = this.afs.collection('stores').doc(SCHOOL.key).collection('academic_environment').doc('academic_environment');
      await ref.update({ attendanceRegistratonMethod: method });
      callback && callback(true, null);
    } catch (error) {
      console.log(error);
      callback && callback(false, error);
    }
  }

  @action
  async fetchScanCardStatus() {
    try {
      const ref = this.afs.collection('stores').doc(SCHOOL.key).collection('academic_environment').doc('academic_environment');
      const data = pushToObject(await ref.get().toPromise());
      return data?.isScanCardOn;
    } catch (error) {
      console.log(error)
    }
  }

  @action
  async updateScanCardStatus(status: any, callback?: (success, error) => void) {
    try {
      const ref = this.afs.collection('stores').doc(SCHOOL.key).collection('academic_environment').doc('academic_environment');
      await ref.update({ isScanCardOn: status });
      callback && callback(true, null);
    } catch (error) {
      console.log(error);
      callback && callback(false, error);
    }
  }

  @action
  async getAllAttendanceMovementsFromSpecificRemarkAndDateMonthKey(dateMonthKey: number, remark: any, selectedAdmission) {
    try {
      const movements = pushToArray(await this.afs.collection('academics_major_admission')
        .doc(selectedAdmission.key).collection('admission_attendance_statistic',
          ref => ref.where('create_date_month_key', '==', dateMonthKey).where('remark.key', '==', remark.key)).get().toPromise());
      let movements_withCreateDateKey = groupByData(movements, 'create_date_key', 'create_date_key');

      const ATTENDANCES_ENUM = [{ name: 'P', value: "5" }, { name: 'M', value: "4" },
      { name: 'A', value: "3" }, { name: 'L', value: "2" }, { name: 'OK', value: "1" }];
      console.log('movements_withCreateDateKey', movements_withCreateDateKey)

      if (SCHOOL.key !== 'ngs-preakleap' && SCHOOL.key !== 'ngs-prek-anchanh') {

        const result = await Promise.all(movements_withCreateDateKey.map(async (movement_withCreateDateKey) => {
          const { create_date_key, data } = movement_withCreateDateKey;
          let attendancesByShift = pushToArray(await this.afs.collection('academics_major_admission')
            .doc(selectedAdmission.key).collection('admission_attendance_statistic',
              ref => ref.where('create_date_key', '==', create_date_key)).get().toPromise());

          let finalRemark: any = data[0].remark;
          data.forEach((item) => {
            const leftOperand = ATTENDANCES_ENUM.find((att) => att.name === item.remark?.name) || ATTENDANCES_ENUM[4];
            const rightOperand = ATTENDANCES_ENUM.find((att) => att.name === finalRemark?.name) || ATTENDANCES_ENUM[4];
            if (leftOperand.value > rightOperand.value) finalRemark = item.remark;
          });

          let allMovements = [];
          attendancesByShift.forEach((attendance) => {
            attendance.attendanceMovements.forEach((movement) => (allMovements.push(movement)));
          });

          const create_date = moment(numberToDate(create_date_key)).format('DD-MM-YYYY');
          allMovements = allMovements.filter((item) => (item.schedule_subject?.key && item.create_date_key === create_date_key))
          allMovements = allMovements.sort((a, b) => (a.session.fromHoursNumber - b.session.fromHoursNumber));

          return { create_date_key, create_date, remark: finalRemark, allMovements };
        }));

        if (remark.name === 'A' && result.length > 0) {
          const movement = result[0];
          const PM = movement['allMovements'].filter((item) => (item.remark?.name === 'P' || item.remark?.name === 'M')).length > 0 ? true : false;
          if (PM) return [];
        }
        return result;
      } else {
        let result = [];
        await Promise.all(movements_withCreateDateKey.map(async (movement_withCreateDateKey) => {
          const { create_date_key, data } = movement_withCreateDateKey;

          data.forEach((attendanceByShift) => {
            const create_date = moment(numberToDate(create_date_key)).format('DD-MM-YYYY');
            let allMovements = attendanceByShift.attendanceMovements.filter((item) => (item.schedule_subject?.key && item.create_date_key === create_date_key))
            allMovements = allMovements.sort((a, b) => (a.session.fromHoursNumber - b.session.fromHoursNumber));
            result.push({
              create_date_key, create_date, shift: attendanceByShift.policyHourShift,
              remark: attendanceByShift.remark, allMovements
            });

          });

          return;
        }));

        result = result.sort((a, b) => (a.create_date_key - b.create_date_key));
        return result;
      }
    } catch (error) {
      console.log(error)
    }
  }


  @action
  async getAdmissionDoc(admissionKey) {
    try {

      const attendanceStatistic = pushToObject(await this.afs.collection('academics_major_admission')
        .doc(admissionKey).get().toPromise());
      return attendanceStatistic;

    } catch (error) {
      console.log(error)
    }
  }


  @action
  async getAttendanceStatisticMovement(admissionKey, monthKey) {
    try {
      // arrayMonthKey.forEach(async monthKey => {
      let attendanceStatistic = pushToArray(await this.afs.collection('academics_major_admission')
        .doc(admissionKey).collection('admission_attendance_statistic', ref => ref.where('create_date_month_key', '==', monthKey)).get().toPromise());
      attendanceStatistic = MappingService.orderBy(attendanceStatistic, 'create_date_key');
      const groupDate = groupByData(attendanceStatistic, 'create_date_key', 'create_date_key');

      let totalA = 0;
      let totalAL = 0;
      let totalAP = 0;
      let totalL = 0;
      let totalM = 0;
      let totalP = 0;

      groupDate.forEach((day, index) => {
        const { data } = day;
        const afternoon = data.filter((item) => (item.policyHourShift.alias !== 'M'));
        const morning = data.filter((item) => (item.policyHourShift.alias === 'M'));
        // console.log(day.create_date_key, 'morning', morning)
        // console.log(day.create_date_key, 'afternoon', afternoon)
        const afternoonM = afternoon.filter((item) => (item.remark.name === 'M')).length > 0 ? true : false;
        const afternoonP = afternoon.filter((item) => (item.remark.name === 'P')).length > 0 ? true : false;
        const afternoonA = afternoon.filter((item) => (item.remark.name === 'A')).length > 0 ? true : false;
        const afternoonL = afternoon.filter((item) => (item.remark.name === 'L')).length > 0 ? true : false;
        const afternoonOK = afternoon.filter((item) => (item.remark.name === 'OK')).length > 0 ? true : false;
        // console.log(day.create_date_key, 'afternoonM', afternoonM, 'afternoonP', afternoonP, 'afternoonA', afternoonA, 'afternoonL', afternoonL, 'afternoonOK', afternoonOK)
        const morningM = morning.filter((item) => (item.remark.name === 'M')).length > 0 ? true : false;
        const morningP = morning.filter((item) => (item.remark.name === 'P')).length > 0 ? true : false;
        const morningA = morning.filter((item) => (item.remark.name === 'A')).length > 0 ? true : false;
        const morningL = morning.filter((item) => (item.remark.name === 'L')).length > 0 ? true : false;
        const morningOK = morning.filter((item) => (item.remark.name === 'OK')).length > 0 ? true : false;
        // console.log(day.create_date_key, 'morningM', morningM, 'morningP', morningP, 'morningA', morningA, 'morningL', morningL, 'morningOK', morningOK)



        /******************** statistic record for Prekleap **********************/
        if (this.schoolConfig.key === 'ngs-preakleap' || this.schoolConfig.key === 'ngs-prek-anchanh') {
          //morning
          if (morningM) {
            totalM++;
          } else if (morningP) {
            totalAP++;
          } else if (morningA) {
            totalA++;
          } else if (morningL) {
            totalL++;
            totalAL++;
          } else if (morningOK) {
            totalP++;
          } else {
          }
          //afternoon

          if (afternoonM) {
            totalM++;
          } else if (afternoonP) {
            totalAP++;
          } else if (afternoonA) {
            totalA++;
          } else if (afternoonL) {
            totalL++;
            totalAL++;
          } else if (afternoonOK) {
            totalP++;
          } else {
          }
        }/********************END statistic record for Prekleap **********************/
        else {
          /******************** statistic record for SSW **********************/
          if (afternoonM || morningM) {
            totalM++;
          } else if (afternoonP || morningP) {
            totalAP++;
          } else if (afternoonA || morningA) {
            totalA++;
          } else if (afternoonL && morningL) {
            totalL = totalL + 2;
            totalAL = totalAL + 2;
          } else if (afternoonL || morningL) {
            totalL++;
            totalAL++;
          } else if (morningOK || afternoonOK) {
            totalP++;
          } else {
            return;
          }
          /********************END statistic record for SSW **********************/
        }
      })

      let remainL = totalL;
      if (totalL >= 3) {
        remainL = totalL % 3
        const addP = Math.floor(totalL / 3)
        totalAP = totalAP + addP
      }
      const statistic = {
        key: monthKey,
        data: monthKey,
        totalA,
        totalAL,
        totalAP,
        totalL: remainL,
        totalM,
        totalP
      }
      // console.log('student', monthKey, statistic)
      // console.log('attendanceStatistic', attendanceStatistic)
      return { attendanceMovement: attendanceStatistic, currentMonth_studentAttendanceStat: statistic };
      // });

    } catch (error) {
      console.log(error)
    }
  }


  @action
  async getBatchStudentAttendanceStatisticMovement(students, arrayMonth) {
    try {
      let batchSta = []
      for (let index = 0; index < students.length; index++) {
        const student = students[index];
        const { admissionKey } = student;
        let studentSta = [];

        for (let index = 0; index < arrayMonth.length; index++) {

          const month = arrayMonth[index];
          // let attendanceStatistic = pushToArray(await this.afs.collection('academics_major_admission')
          // .doc(admissionKey).collection('admission_attendance_statistic', ref => ref.where('create_date_month_key', '==', month.dateKey)).get().toPromise());
          // attendanceStatistic = MappingService.orderBy(attendanceStatistic, 'create_date_key');

          let attendanceMovementsRef = pushToArray(await this.afs.collection('students').doc(student.student.key).collection('student_attendance_movement', ref =>
            ref.where('create_date_month_key', '==', month.dateKey)).get().toPromise());

          attendanceMovementsRef = MappingService.orderBy(attendanceMovementsRef, 'create_date_key');
          const groupDate = groupByData(attendanceMovementsRef, 'create_date_key', 'create_date_key');

          let student_attendance_statistic = pushToObject(await this.afs.collection('academics_major_admission')
            .doc(admissionKey).collection('student_attendance_statistic').doc(month.dateKey.toString()).get().toPromise());
          // attendanceStatistic = MappingService.orderBy(attendanceStatistic, 'create_date_key');


          let totalA = 0;
          let totalAL = 0;
          let totalAP = 0;
          let totalL = 0;
          let totalM = 0;
          let totalP = 0;

          groupDate.forEach((day, index) => {
            const { data } = day;
            const afternoon = data.filter((item) => (item?.policy?.shiftPolicyHour?.alias !== 'M'));
            const morning = data.filter((item) => (item?.policy?.shiftPolicyHour?.alias === 'M'));
            const noPolicy = data.filter((item) => (!item?.policy?.shiftPolicyHour));
            if (noPolicy.length > 0) {
              console.log(student.student.key, student.student.puc_id, day.create_date_key, 'noPolicy', noPolicy, day)
            }

            // console.log(day.create_date_key, 'morning', morning)
            // console.log(day.create_date_key, 'afternoon', afternoon)
            const afternoonM = afternoon.filter((item) => (item.remark.name === 'M')).length > 0 ? true : false;
            const afternoonP = afternoon.filter((item) => (item.remark.name === 'P')).length > 0 ? true : false;
            const afternoonA = afternoon.filter((item) => (item.remark.name === 'A')).length > 0 ? true : false;
            const afternoonL = afternoon.filter((item) => (item.remark.name === 'L')).length > 0 ? true : false;
            const afternoonOK = afternoon.filter((item) => (item.remark.name === 'OK')).length > 0 ? true : false;
            // console.log(day.create_date_key, 'afternoonM', afternoonM, 'afternoonP', afternoonP, 'afternoonA', afternoonA, 'afternoonL', afternoonL, 'afternoonOK', afternoonOK)
            const morningM = morning.filter((item) => (item.remark.name === 'M')).length > 0 ? true : false;
            const morningP = morning.filter((item) => (item.remark.name === 'P')).length > 0 ? true : false;
            const morningA = morning.filter((item) => (item.remark.name === 'A')).length > 0 ? true : false;
            const morningL = morning.filter((item) => (item.remark.name === 'L')).length > 0 ? true : false;
            const morningOK = morning.filter((item) => (item.remark.name === 'OK')).length > 0 ? true : false;
            // console.log(day.create_date_key, 'morningM', morningM, 'morningP', morningP, 'morningA', morningA, 'morningL', morningL, 'morningOK', morningOK)



            /******************** statistic record for Prekleap **********************/
            if (this.schoolConfig.key === 'ngs-preakleap' || this.schoolConfig.key === 'ngs-prek-anchanh') {
              //morning
              if (morningM) {
                totalM++;
              } else if (morningP) {
                totalAP++;
              } else if (morningA) {
                totalA++;
              } else if (morningL) {
                totalL++;
                totalAL++;
              } else if (morningOK) {
                totalP++;
              } else {
              }
              //afternoon

              if (afternoonM) {
                totalM++;
              } else if (afternoonP) {
                totalAP++;
              } else if (afternoonA) {
                totalA++;
              } else if (afternoonL) {
                totalL++;
                totalAL++;
              } else if (afternoonOK) {
                totalP++;
              } else {
              }
            }/********************END statistic record for Prekleap **********************/
            else {
              /******************** statistic record for SSW **********************/
              if (afternoonM || morningM) {
                totalM++;
              } else if (afternoonP || morningP) {
                totalAP++;
              } else if (afternoonA || morningA) {
                totalA++;
              } else if (afternoonL && morningL) {
                totalL = totalL + 2;
                totalAL = totalAL + 2;
              } else if (afternoonL || morningL) {
                totalL++;
                totalAL++;
              } else if (morningOK || afternoonOK) {
                totalP++;
              } else {
                return;
              }
              /********************END statistic record for SSW **********************/
            }
          })

          let remainL = totalL;
          if (totalL >= 3) {
            remainL = totalL % 3
            const addP = Math.floor(totalL / 3)
            totalAP = totalAP + addP
          }
          const statistic = {
            key: month.key,
            data: month.dateKey,
            totalA,
            totalAL,
            totalAP,
            totalL: remainL,
            totalM,
            totalP
          }
          // console.log('student', monthKey, statistic)
          // console.log('attendanceStatistic', attendanceStatistic)
          const data = { attendanceMovement: attendanceMovementsRef, currentMonth_studentAttendanceStat: statistic, admissionStatistic: student_attendance_statistic };
          studentSta.push(data)
        }

        // arrayMonth.forEach(async month => {

        //   // // let attendanceStatistic = pushToArray(await this.afs.collection('academics_major_admission')
        //   // // .doc(admissionKey).collection('admission_attendance_statistic', ref => ref.where('create_date_month_key', '==', monthKey)).get().toPromise());
        //   // // attendanceStatistic = MappingService.orderBy(attendanceStatistic, 'create_date_key');

        //   // let attendanceMovementsRef = pushToArray(await this.afs.collection('students').doc(student.student.key).collection('student_attendance_movement', ref =>
        //   //   ref.where('create_date_month_key', '==', month.dateKey)).get().toPromise());
        //   //   // console.log('student', student.student.key, month, attendanceMovementsRef)

        //   // attendanceMovementsRef = MappingService.orderBy(attendanceMovementsRef, 'create_date_key');
        //   // const groupDate = groupByData(attendanceMovementsRef, 'create_date_key', 'create_date_key');

        //   // let totalA = 0;
        //   // let totalAL = 0;
        //   // let totalAP = 0;
        //   // let totalL = 0;
        //   // let totalM = 0;
        //   // let totalP = 0;

        //   // groupDate.forEach((day, index) => {
        //   //   const { data } = day;
        //   //   const afternoon = data.filter((item) => (item?.policy?.shiftPolicyHour?.alias !== 'M'));
        //   //   const morning = data.filter((item) => (item?.policy?.shiftPolicyHour?.alias === 'M'));
        //   //   // console.log(day.create_date_key, 'morning', morning)
        //   //   // console.log(day.create_date_key, 'afternoon', afternoon)
        //   //   const afternoonM = afternoon.filter((item) => (item.remark.name === 'M')).length > 0 ? true : false;
        //   //   const afternoonP = afternoon.filter((item) => (item.remark.name === 'P')).length > 0 ? true : false;
        //   //   const afternoonA = afternoon.filter((item) => (item.remark.name === 'A')).length > 0 ? true : false;
        //   //   const afternoonL = afternoon.filter((item) => (item.remark.name === 'L')).length > 0 ? true : false;
        //   //   const afternoonOK = afternoon.filter((item) => (item.remark.name === 'OK')).length > 0 ? true : false;
        //   //   // console.log(day.create_date_key, 'afternoonM', afternoonM, 'afternoonP', afternoonP, 'afternoonA', afternoonA, 'afternoonL', afternoonL, 'afternoonOK', afternoonOK)
        //   //   const morningM = morning.filter((item) => (item.remark.name === 'M')).length > 0 ? true : false;
        //   //   const morningP = morning.filter((item) => (item.remark.name === 'P')).length > 0 ? true : false;
        //   //   const morningA = morning.filter((item) => (item.remark.name === 'A')).length > 0 ? true : false;
        //   //   const morningL = morning.filter((item) => (item.remark.name === 'L')).length > 0 ? true : false;
        //   //   const morningOK = morning.filter((item) => (item.remark.name === 'OK')).length > 0 ? true : false;
        //   //   // console.log(day.create_date_key, 'morningM', morningM, 'morningP', morningP, 'morningA', morningA, 'morningL', morningL, 'morningOK', morningOK)



        //   //   /******************** statistic record for Prekleap **********************/
        //   //   if (this.schoolConfig.key === 'ngs-preakleap' || this.schoolConfig.key === 'ngs-prek-anchanh') {
        //   //     //morning
        //   //     if (morningM) {
        //   //       totalM++;
        //   //     } else if (morningP) {
        //   //       totalAP++;
        //   //     } else if (morningA) {
        //   //       totalA++;
        //   //     } else if (morningL) {
        //   //       totalL++;
        //   //       totalAL++;
        //   //     } else if (morningOK) {
        //   //       totalP++;
        //   //     } else {
        //   //     }
        //   //     //afternoon

        //   //     if (afternoonM) {
        //   //       totalM++;
        //   //     } else if (afternoonP) {
        //   //       totalAP++;
        //   //     } else if (afternoonA) {
        //   //       totalA++;
        //   //     } else if (afternoonL) {
        //   //       totalL++;
        //   //       totalAL++;
        //   //     } else if (afternoonOK) {
        //   //       totalP++;
        //   //     } else {
        //   //     }
        //   //   }/********************END statistic record for Prekleap **********************/
        //   //   else {
        //   //     /******************** statistic record for SSW **********************/
        //   //     if (afternoonM || morningM) {
        //   //       totalM++;
        //   //     } else if (afternoonP || morningP) {
        //   //       totalAP++;
        //   //     } else if (afternoonA || morningA) {
        //   //       totalA++;
        //   //     } else if (afternoonL && morningL) {
        //   //       totalL = totalL + 2;
        //   //       totalAL = totalAL + 2;
        //   //     } else if (afternoonL || morningL) {
        //   //       totalL++;
        //   //       totalAL++;
        //   //     } else if (morningOK || afternoonOK) {
        //   //       totalP++;
        //   //     } else {
        //   //       return;
        //   //     }
        //   //     /********************END statistic record for SSW **********************/
        //   //   }
        //   // })

        //   // let remainL = totalL;
        //   // if (totalL >= 3) {
        //   //   remainL = totalL % 3
        //   //   const addP = Math.floor(totalL / 3)
        //   //   totalAP = totalAP + addP
        //   // }
        //   // const statistic = {
        //   //   key: month.key,
        //   //   data: month.dateKey,
        //   //   totalA,
        //   //   totalAL,
        //   //   totalAP,
        //   //   totalL: remainL,
        //   //   totalM,
        //   //   totalP
        //   // }
        //   // // console.log('student', monthKey, statistic)
        //   // // console.log('attendanceStatistic', attendanceStatistic)
        //   // const data = { attendanceMovement: attendanceMovementsRef, currentMonth_studentAttendanceStat: statistic };
        //   // studentSta.push(data)
        //   return { attendanceMovement: attendanceMovementsRef, currentMonth_studentAttendanceStat: statistic };
        // });
        const data = {
          student: student,
          studentSta
        }
        batchSta.push(data)
        // return data
      }
      return batchSta


    } catch (error) {
      console.log(error)
    }
  }


  //update resolve 2 admission student attendance statistic
  @action
  async get_Clone_AttendanceStatisticMovement(item) {
    try {
      const { puc_id, fromAdmissionKey, toAdmissionKey, updateAdmissionKeyInBatch } = item;

      const attendanceStatistic = pushToArray(await this.afs.collection('academics_major_admission')
        .doc(fromAdmissionKey).collection('admission_attendance_statistic').get().toPromise());

      const studentAttStatistic = pushToArray(await this.afs.collection('academics_major_admission')
        .doc(fromAdmissionKey).collection('student_attendance_statistic').get().toPromise());

      const batchStudent = pushToArray(await this.afs.collection('institute_training_level_batch')
        .doc(updateAdmissionKeyInBatch).collection('students', ref => ref.where('student.puc_id', '==', puc_id)).get().toPromise());

      const batchMember = pushToArray(await this.afs.collection('institute_training_level_batch')
        .doc(updateAdmissionKeyInBatch).collection('batch_members', ref => ref.where('student.puc_id', '==', puc_id)).get().toPromise());


      batchMember.forEach(element => {
        this.afs.collection('institute_training_level_batch').doc(updateAdmissionKeyInBatch).collection('students').doc(element.key).update({
          admissionKey: toAdmissionKey
        });
      });

      batchStudent.forEach(element => {
        this.afs.collection('institute_training_level_batch').doc(updateAdmissionKeyInBatch).collection('batch_members').doc(element.key).update({
          admissionKey: toAdmissionKey
        });
      });

      studentAttStatistic.forEach(element => {
        const key = element.key.toString()
        this.afs.collection('academics_major_admission').doc(toAdmissionKey).collection('student_attendance_statistic').doc(key).set(element);
      });
      attendanceStatistic.forEach(element => {
        this.afs.collection('academics_major_admission').doc(toAdmissionKey).collection('admission_attendance_statistic').doc(element.key).set(element);
      });

      // return attendanceStatistic;
    } catch (error) {
      console.log(error)
    }
  }

  //resolve student monthly attendance statistic
  @action
  async updateAttendanceStatisticMovement(item, arrayMonthKey) {
    try {
      // console.log(admissionKey, monthKey)
      const { puc_id, fromAdmissionKey, toAdmissionKey, updateAdmissionKeyInBatch } = item;

      arrayMonthKey.forEach(async monthKey => {

        let attendanceStatistic = pushToArray(await this.afs.collection('academics_major_admission')
          .doc(toAdmissionKey).collection('admission_attendance_statistic', ref => ref.where('create_date_month_key', '==', monthKey)).get().toPromise());
        attendanceStatistic = MappingService.orderBy(attendanceStatistic, 'create_date_key');
        const groupDate = groupByData(attendanceStatistic, 'create_date_key', 'create_date_key');

        let totalA = 0;
        let totalAL = 0;
        let totalAP = 0;
        let totalL = 0;
        let totalM = 0;
        let totalP = 0;

        groupDate.forEach((day, index) => {
          const { data } = day;
          const afternoon = data.filter((item) => (item.policyHourShift.alias !== 'M'));
          const morning = data.filter((item) => (item.policyHourShift.alias === 'M'));
          // console.log(day.create_date_key, 'morning', morning)
          // console.log(day.create_date_key, 'afternoon', afternoon)
          const afternoonM = afternoon.filter((item) => (item.remark.name === 'M')).length > 0 ? true : false;
          const afternoonP = afternoon.filter((item) => (item.remark.name === 'P')).length > 0 ? true : false;
          const afternoonA = afternoon.filter((item) => (item.remark.name === 'A')).length > 0 ? true : false;
          const afternoonL = afternoon.filter((item) => (item.remark.name === 'L')).length > 0 ? true : false;
          const afternoonOK = afternoon.filter((item) => (item.remark.name === 'OK')).length > 0 ? true : false;
          // console.log(day.create_date_key, 'afternoonM', afternoonM, 'afternoonP', afternoonP, 'afternoonA', afternoonA, 'afternoonL', afternoonL, 'afternoonOK', afternoonOK)
          const morningM = morning.filter((item) => (item.remark.name === 'M')).length > 0 ? true : false;
          const morningP = morning.filter((item) => (item.remark.name === 'P')).length > 0 ? true : false;
          const morningA = morning.filter((item) => (item.remark.name === 'A')).length > 0 ? true : false;
          const morningL = morning.filter((item) => (item.remark.name === 'L')).length > 0 ? true : false;
          const morningOK = morning.filter((item) => (item.remark.name === 'OK')).length > 0 ? true : false;
          // console.log(day.create_date_key, 'morningM', morningM, 'morningP', morningP, 'morningA', morningA, 'morningL', morningL, 'morningOK', morningOK)

          //********************** SSW Statistic *******************************/
          // if (afternoonM || morningM) {
          //   totalM++;
          // } else if (afternoonP || morningP) {
          //   totalAP++;
          // } else if (afternoonA || morningA) {
          //   totalA++;
          // } else if (afternoonL && morningL) {
          //   totalL = totalL + 2;
          //   totalAL = totalAL + 2;
          // } else if (afternoonL || morningL) {
          //   totalL++;
          //   totalAL++;
          // } else if(afternoonOK || morningOK){
          //   totalP++;
          // }else{

          // }
          //********************** END SSW Statistic *******************************/

          //********************** Prekleap Statistic *******************************/
          if (morningM) {
            totalM++;
          } else if (morningP) {
            totalAP++;
          } else if (morningA) {
            totalA++;
          } else if (morningL) {
            totalL++;
            totalAL++;
          } else if (morningOK) {
            totalP++;
          } else {

          }

          if (afternoonM) {
            totalM++;
          } else if (afternoonP) {
            totalAP++;
          } else if (afternoonA) {
            totalA++;
          } else if (afternoonL) {
            totalL++;
            totalAL++;
          } else if (afternoonOK) {
            totalP++;
          } else {

          }



          //********************** END Prekleap Statistic *******************************/


        })
        let remainL = totalL;
        if (totalL >= 3) {
          remainL = totalL % 3
          const addP = Math.floor(totalL / 3)
          totalAP = totalAP + addP
        }

        const statistic = {
          key: monthKey,
          data: monthKey,
          totalA,
          totalAL,
          totalAP,
          totalL: remainL,
          totalM,
          totalP
        }

        // if (admissionKey === '6qVjn3GllY3wJjNUCGLv') {
        // console.log(admissionKey, statistic)
        const monthKeytoString = monthKey.toString();
        this.afs.collection('academics_major_admission').doc(toAdmissionKey).collection('student_attendance_statistic').doc(monthKeytoString).set(statistic);
        //  //  return  ;
        // }
      })

    } catch (error) {
      console.log(error)
    }
  }
  //END resolve student monthly attendance statistic


  @action
  async getAttendanceStatisticMonthly(admissionKey) {
    try {
      // let studentInfo = pushToObject(await this.afs.collection('students').doc(studentKey).get().toPromise());
      let attendanceStatistic = pushToArray(await this.afs.collection('academics_major_admission')
        .doc(admissionKey).collection('student_attendance_statistic').get().toPromise());
      attendanceStatistic = attendanceStatistic.map((item) => ({ ...item, date: numberToDate(parseInt(item.key + '01')) }));
      return attendanceStatistic;
    } catch (error) {
      console.log(error)
    }
  }

  @action
  async deleteStudentsTestingTimeAttsAndAttMovementsFromCurrentBatch(batchKey: string, dateKey: number) {
    try {
      const students = pushToArray(await this.afs.collection('institute_training_level_batch').doc(batchKey).collection('students').get().toPromise());

      for await (const [index, item] of students.entries()) {
        const { student } = item;
        const timeAttendancesRef = this.afs.collection('students').doc(student.key).collection('time_attendance', ref => ref.where('create_date_key', '==', dateKey));
        const timeAttendancesDoc = pushToArray(await timeAttendancesRef.get().toPromise());
        const attendanceMovementsRef = this.afs.collection('students').doc(student.key).collection('student_attendance_movement', ref => ref.where('create_date_key', '==', dateKey));
        const attendanceMovementsDoc = pushToArray(await attendanceMovementsRef.get().toPromise());
        const permissionsRef = this.afs.collection('students').doc(student.key).collection('permissions');
        const permissionsDoc = pushToArray(await permissionsRef.get().toPromise());
        await Promise.all(timeAttendancesDoc.map(async (item) => {
          await this.afs.collection('students').doc(student.key).collection('time_attendance').doc(item.key).delete();
          await this.afs.collection('stores').doc(SCHOOL.key).collection('time_attendance').doc(item.key).delete();
        }));

        await Promise.all(attendanceMovementsDoc.map(async (item) => {
          await this.afs.collection('students').doc(student.key).collection('student_attendance_movement').doc(item.key).delete();
          await this.afs.collection('stores').doc(SCHOOL.key).collection('student_attendance_movement').doc(item.key).delete();
        }));

        await Promise.all(permissionsDoc.map(async (item) => {
          await this.afs.collection('students').doc(student.key).collection('permissions').doc(item.key).delete();
          await this.afs.collection('stores').doc(SCHOOL.key).collection('campus').doc('8CUqw3o6xIOzQlYKuVUb').collection('permissions').doc(item.key).delete();
          await this.afs.collection('stores').doc(SCHOOL.key).collection('permissions').doc(item.key).delete();
        }));
      }
      return;
    } catch (error) {
      console.log(error);
    }
  }

  @action
  async deleteCurrentEmployeeTimeAttsAndAttMovements(employeeKey: string, dateKey: number) {
    try {
      const timeAttendancesRef = this.afs.collection('employees').doc(employeeKey).collection('employee_time_attendance', ref => ref.where('create_date_key', '==', dateKey));
      const timeAttendancesDoc = pushToArray(await timeAttendancesRef.get().toPromise());
      const attendanceMovementsRef = this.afs.collection('employees').doc(employeeKey).collection('employee_attendance_movement', ref => ref.where('create_date_key', '==', dateKey));
      const attendanceMovementsDoc = pushToArray(await attendanceMovementsRef.get().toPromise());
      await Promise.all(timeAttendancesDoc.map(async (item) => {
        await this.afs.collection('employees').doc(employeeKey).collection('employee_time_attendance').doc(item.key).delete();
        await this.afs.collection('stores').doc(SCHOOL.key).collection('employee_time_attendance').doc(item.key).delete();
      }));

      await Promise.all(attendanceMovementsDoc.map(async (item) => {
        await this.afs.collection('employees').doc(employeeKey).collection('employee_attendance_movement').doc(item.key).delete();
        await this.afs.collection('stores').doc(SCHOOL.key).collection('employee_attendance_movement').doc(item.key).delete();
      }));
      return;
    } catch (error) {
      console.log(error);
    }
  }

  @action
  async deleteCurrentAttendanceConfirmationLogs(employeeKey: string, dateKey: number) {
    try {
      const attendanceConfirmationLogsRef = this.afs.collection('stores').doc(SCHOOL.key).collection('attendance_confirmation_logs',
        ref => ref.where('create_by.key', '==', employeeKey).where('date_key', '==', dateKey));
      const attendanceConfirmationLogsDoc = pushToArray(await attendanceConfirmationLogsRef.get().toPromise());
      await Promise.all(attendanceConfirmationLogsDoc.map(async (item) => {
        await this.afs.collection('stores').doc(SCHOOL.key).collection('attendance_confirmation_logs').doc(item.key).delete();
      }));
      return;
    } catch (error) {
      console.log(error);
    }
  }

  @action
  async deleteEmployeeAttendanceStatisticDocs(employeeKey: string, dateKey: number) {
    try {
      const employeeAttendanceByShiftRef = this.afs.collection('employees').doc(employeeKey).collection('employee_attendance_by_shift');
      const employeeAttendanceByShiftDoc = pushToArray(await employeeAttendanceByShiftRef.get().toPromise());
      const employeeAttendanceStatisticRef = this.afs.collection('employees').doc(employeeKey).collection('employee_attendance_statistic');
      const employeeAttendanceStatisticDoc = pushToArray(await employeeAttendanceStatisticRef.get().toPromise());
      await Promise.all(employeeAttendanceByShiftDoc.map(async (item) => {
        await this.afs.collection('employees').doc(employeeKey).collection('employee_attendance_by_shift').doc(item.key).delete();
      }));
      await Promise.all(employeeAttendanceStatisticDoc.map(async (item) => {
        await this.afs.collection('employees').doc(employeeKey).collection('employee_attendance_statistic').doc(item.key + '').delete();
      }));
      return;
    } catch (error) {
      console.log(error);
    }
  }

  @action
  async deleteEmployeePermissionDocs(employeeKey: string, dateKey: number) {
    try {
      const employeePermissionsRef = this.afs.collection('stores').doc(SCHOOL.key).collection('employee_permissions');
      const employeePermissionsDoc = pushToArray(await employeePermissionsRef.get().toPromise());
      const employeePermissionsRef0 = this.afs.collection('employees').doc(employeeKey).collection('employee_permissions');
      const employeePermissionsDoc0 = pushToArray(await employeePermissionsRef0.get().toPromise());
      await Promise.all(employeePermissionsDoc.map(async (item) => {
        await this.afs.collection('stores').doc(SCHOOL.key).collection('employee_permissions').doc(item.key).delete();
      }));
      await Promise.all(employeePermissionsDoc0.map(async (item) => {
        await this.afs.collection('employees').doc(employeeKey).collection('employee_permissions').doc(item.key).delete();
      }));
      return;
    } catch (error) {
      console.log(error);
    }
  }

  @action
  async deleteEmployeeScanCardAttendanceDocs(employeeKey: string, dateKey: number) {
    try {
      const EmployeeScanCardAttendancesRef = this.afs.collection('stores').doc(SCHOOL.key).collection('employee_scan_card_attendance');
      const EmployeeScanCardAttendancesDoc = pushToArray(await EmployeeScanCardAttendancesRef.get().toPromise());
      // console.log('employeePermissionsDoc', EmployeeScanCardAttendancesDoc)
      await Promise.all(EmployeeScanCardAttendancesDoc.map(async (item) => {
        await this.afs.collection('stores').doc(SCHOOL.key).collection('employee_scan_card_attendance').doc(item.key).delete();
      }));
      // console.log('deleteCurrentAttendanceConfirmationLogs: deleting done.');
      return;
    } catch (error) {
      console.log(error);
    }
  }


  @action
  async getAttendanceConfirmationLogs(batchKey: string, scheduleKey: string, dateKey: number) {
    try {
      const attendanceConfirmationLogsDoc = await this.ds.storeRef().collection('attendance_confirmation_logs', ref => {
        let condition = ref.where('batchKey', '==', batchKey);
        condition = condition.where('scheduleKey', '==', scheduleKey);
        condition = condition.where('create_date_key', '==', dateKey);
        return condition;
      }).get().toPromise();
      const attendanceConfirmationLogs = pushToArray(attendanceConfirmationLogsDoc);
      return attendanceConfirmationLogs;
    } catch (error) {
      console.log(error);
    }
  }

  @action
  async resolvedStudentAdmissionToGetAdmissionStat() {
    try {
      let chunkIndex = 0;
      let admissionsData = pushToArray(await this.afs.collection('academics_major_admission', ref => {
        let condition = ref.where('page_key', '>', 20210101000000).where('status.key', '==', 1).where('status.text', '==', 'Active');
        return condition.orderBy('page_key', 'desc').limit(10)
      }).get().toPromise());
      let lastVisible = admissionsData.length > 0 ? admissionsData[admissionsData.length - 1] : null;

      while (lastVisible) {
        for await (const [index, admissionData] of admissionsData.entries()) {
          console.log(`chunkIndex: ${chunkIndex}, index: ${index}, key: ${admissionData.key}, status: ${admissionData.status.text}`);
          await this.afs.collection('academics_major_admission').doc(admissionData.key).update({
            status: {
              key: admissionData.status.key,
              text: 'Actives'
            }
          });
        }

        admissionsData = pushToArray(await this.afs.collection('academics_major_admission', ref => {
          let condition = ref.where('page_key', '>', 20210101000000).where('status.key', '==', 1).where('status.text', '==', 'Active').orderBy('page_key', 'desc');
          if (lastVisible) condition = condition.startAfter(lastVisible['page_key']);
          return condition.limit(10)
        }).get().toPromise());
        lastVisible = admissionsData.length > 0 ? admissionsData[admissionsData.length - 1] : null;

        console.log(`chunkIndex: ${chunkIndex}, lastVisible: ${lastVisible.page_key}`);
        await new Promise((resolve) => { setTimeout(() => { resolve(chunkIndex) }, 2500) });
        chunkIndex++;
      }
    } catch (error) {
      console.log(error);
    }
  }

  @action
  async get_isPriority_permission_convertTo_isPrioritizedMovement() {
    try {
      const groupPolicyData = pushToArray(await this.ds.storeDocRef(SCHOOL.key)
        .collection('student_attendance_movement', ref => ref.where('isPriority_permission', '==', true)).get().toPromise());
      for await (const [index, item] of groupPolicyData.entries()) {
        await this.ds.storeDocRef(SCHOOL.key).collection('student_attendance_movement').doc(item.key).update({ isPrioritizedMovement: true });
        await this.ds.collectionRef('students').doc(item.studentKey).collection('student_attendance_movement').doc(item.key).update({ isPrioritizedMovement: true });
        await new Promise((resolve) => {
          setTimeout(() => {
            console.log(`${index + 1} - studentkey: ${item.studentKey} - movementKey: ${item.key} of ${groupPolicyData.length} done.`)
            resolve(index)
          }, 500)
        })
      }
    } catch (error) {
      console.log(error);
    }
  }

  @action
  async get_permissionShift_convertTo_prioritizedShift() {
    try {
      let movements = pushToArray(await this.ds.storeDocRef(SCHOOL.key).collection('student_attendance_movement', ref => {
        let condition = ref.orderBy('permissionShift.key').orderBy('create_date', 'desc');
        return condition;
      }).get().toPromise());

      for await (const [index, item] of movements.entries()) {
        await this.ds.storeDocRef(SCHOOL.key).collection('student_attendance_movement').doc(item.key).update({
          isPrioritizedMovement: true,
          prioritizedPageKey: toPageKey(item.create_date.toDate()),
          prioritizedShift: item.permissionShift,
          isPrioritizedResolved: true
        });

        await this.ds.collectionRef('students').doc(item.studentKey).collection('student_attendance_movement').doc(item.key).update({
          isPrioritizedMovement: true,
          prioritizedPageKey: toPageKey(item.create_date.toDate()),
          prioritizedShift: item.permissionShift,
          isPrioritizedResolved: true
        });

        await new Promise((resolve) => {
          setTimeout(() => {
            console.log(`${index + 1} - studentkey: ${item.studentKey} - movementKey: ${item.key} of ${movements.length} done.`)
            resolve(index)
          }, 500)
        })
      }

      // while(true) {
      //   let lastVisible = JSON.parse(localStorage.getItem('lastVisibleMovement'));
      //   let movements = pushToArray(await this.ds.storeDocRef(SCHOOL.key).collection('student_attendance_movement', ref => {
      //     let condition = ref.orderBy('permissionShift.key').orderBy('create_date', 'desc');
      //     if(lastVisible) condition = condition.startAfter(lastVisible['create_date']);
      //     return condition.limit(10);
      //   }).get().toPromise());

      //   if(movements.length === 0) break;
      //   for await (const [index, item] of movements.entries()) {
      //     // await this.ds.storeDocRef(SCHOOL.key).collection('student_attendance_movement').doc(item.key).update({
      //     //   isPrioritizedMovement: true,
      //     //   prioritizedPageKey: toPageKey(item.create_date),
      //     //   prioritizedShift: item.permissionShift
      //     // });

      //     // await this.ds.collectionRef('students').doc(item.studentKey).collection('student_attendance_movement').doc(item.key).update({
      //     //   isPrioritizedMovement: true,
      //     //   prioritizedPageKey: toPageKey(item.create_date),
      //     //   prioritizedShift: item.permissionShift
      //     // });

      //     await new Promise((resolve) => {
      //       setTimeout(() => {
      //         console.log(`${index + 1} - studentkey: ${item.studentKey} - movementKey: ${item.key} of ${movements.length} done.`)
      //         resolve(index)
      //       }, 500)
      //     })
      //   }

      //   if(movements.length > 0) {
      //     console.log(movements[movements.length - 1])
      //     localStorage.setItem('lastVisibleMovement', JSON.stringify({ create_date: movements[movements.length - 1].create_date }));
      //   }
      // }
    } catch (error) {
      console.log(error);
    }
  }

  @action
  async setEmployeesAutoHaveOneGroupPolicy() {
    try {
      const employees = pushToArray(await this.afs.collection('employees', ref => ref.where('schoolKey', '==', 'apis_school')).get().toPromise());
      for await (const [index, item] of employees.entries()) {
        await this.afs.collection('employees').doc(item.key).update({
          instructor_attendance_group_policy_keys: [],
          employee_attendance_group_policy_keys: ['iqZsf9QjqJqPpNghMRqR']
        });
        // console.log(`${index + 1}. ${item.key} done of ${employees.length}.`);
      }
    } catch (error) {
      console.log(error);
    }
  }

  @action
  async deleteTestStudentAttendanceMovement(item, dateKey) {
    try {
      this.loading = true;
      // dateKey = 20230209
      for await (const [index, e] of item.entries()) {
        const { student, admissionKey } = e;
        // const timeAttendancesRef = this.afs.collection('students').doc(student.key).collection('time_attendance'
        //   , ref => ref.where('create_date_key', '==', dateKey)
        // );
        // const timeAttendancesDoc = pushToArray(await timeAttendancesRef.get().toPromise());
        // const attendanceMovementsRef = this.afs.collection('students').doc(student.key).collection('student_attendance_movement'
        //   , ref => ref.where('create_date_key', '==', dateKey)
        // );
        // const attendanceMovementsDoc = pushToArray(await attendanceMovementsRef.get().toPromise());

        // await Promise.all(timeAttendancesDoc.map(async (item) => {
        //   await this.afs.collection('students').doc(student.key).collection('time_attendance').doc(item.key).delete();
        //   await this.afs.collection('stores').doc(SCHOOL.key).collection('time_attendance').doc(item.key).delete();
        // }));

        // await Promise.all(attendanceMovementsDoc.map(async (item) => {
        //   await this.afs.collection('students').doc(student.key).collection('student_attendance_movement').doc(item.key).delete();
        //   await this.afs.collection('stores').doc(SCHOOL.key).collection('student_attendance_movement').doc(item.key).delete();
        // }));



        // **************** Delete student all time attendance && movement
        const permissionsRef = this.afs.collection('students').doc(student.key).collection('permissions');
        const permissionsDoc = pushToArray(await permissionsRef.get().toPromise());

        await Promise.all(permissionsDoc.map(async (item) => {
          await this.afs.collection('students').doc(student.key).collection('permissions').doc(item.key).delete();
          await this.afs.collection('stores').doc(SCHOOL.key).collection('campus').doc(item?.campusKey).collection('permissions').doc(item.key).delete();
          await this.afs.collection('stores').doc(SCHOOL.key).collection('permissions').doc(item.key).delete();
        }));


        const admission_attendance_statisticRef = pushToArray(await this.afs.collection('academics_major_admission').doc(admissionKey).collection('admission_attendance_statistic').get().toPromise())
        admission_attendance_statisticRef.forEach(async att => {
          if (att && att.key) {
            await this.afs.collection('academics_major_admission').doc(admissionKey).collection('admission_attendance_statistic').doc(att.key).delete();
          }
        });

        const student_attendance_statisticRef = pushToArray(await this.afs.collection('students').doc(student.key).collection('student_attendance_statistic').get().toPromise())
        student_attendance_statisticRef.forEach(async att => {
          if (att && att.key) {
            await this.afs.collection('academics_major_admission').doc(admissionKey).collection('student_attendance_statistic').doc(att.key).delete();
          }
        });

        const timeAttendancesRef = pushToArray(await this.ds.collection('students').doc(student.key).collection('time_attendance').get().toPromise())
        timeAttendancesRef.forEach(async att => {
          if (att && att.key) {
            await this.ds.collection('students').doc(student.key).collection('time_attendance').doc(att.key).delete();
          }
        });

        const timeAttendancesStoreRef = pushToArray(await this.ds.collection('stores').doc(SCHOOL.key).collection('time_attendance', ref => ref.where('studentKey', '==', student.key)).get().toPromise())
        timeAttendancesStoreRef.forEach(async att => {
          if (att && att.key) {
            await this.ds.collection('stores').doc(SCHOOL.key).collection('time_attendance').doc(att.key).delete();
          }
        });

        const timeAttendancesMovementStoreRef = pushToArray(await this.ds.collection('stores').doc(SCHOOL.key).collection('student_attendance_movement', ref => ref.where('studentKey', '==', student.key)).get().toPromise())
        timeAttendancesMovementStoreRef.forEach(async att => {
          if (att && att.key) {
            await this.ds.collection('stores').doc(SCHOOL.key).collection('student_attendance_movement').doc(att.key).delete()
          }
        });
        const timeAttendancesMovementRef = pushToArray(await this.ds.collection('students').doc(student.key).collection('student_attendance_movement').get().toPromise())
        timeAttendancesMovementRef.forEach(async att => {
          if (att && att.key) {
            await this.ds.collection('students').doc(student.key).collection('student_attendance_movement').doc(att.key).delete()
          }
        });

        // console.log(timeAttendancesMovementRef)

        console.log(`${index + 1} - ${student.puc_id} done of ${item.length}.`);
        if (index === item.length - 1) {
          console.log('Finished');
        }
      }
      this.loading = false;
      return;
    } catch (error) {
      console.log(error);
    }
  }

  @action
  async deleteTestStudentScanQRAttendanceMovement(item) {
    try {
      this.loading = true;
      // dateKey = 20230209
      for await (const [index, e] of item.entries()) {
        const { student, admissionKey } = e;

        const admission_attendance_statisticRef = pushToArray(await this.afs.collection('academics_major_admission').doc(admissionKey).collection('admission_attendance_statistic').get().toPromise())
        admission_attendance_statisticRef.forEach(async att => {
          if (att && att.key) {
            await this.afs.collection('academics_major_admission').doc(admissionKey).collection('admission_attendance_statistic').doc(att.key).delete();
          }
        });

        const student_attendance_statisticRef = pushToArray(await this.afs.collection('academics_major_admission').doc(admissionKey).collection('student_attendance_statistic').get().toPromise())
        student_attendance_statisticRef.forEach(async att => {
          if (att && att.key) {
            await this.afs.collection('academics_major_admission').doc(admissionKey).collection('student_attendance_statistic').doc(att.key).delete();
          }
        });

        const timeAttendancesRef = pushToArray(await this.ds.collection('students').doc(student.key).collection('time_attendance').get().toPromise())
        timeAttendancesRef.forEach(async att => {
          if (att && att.key) {
            await this.ds.collection('students').doc(student.key).collection('time_attendance').doc(att.key).delete();
          }
        });

        const studentScanQrcodeAttendanceRef = pushToArray(await this.ds.collection('students').doc(student.key).collection('scan_qrcode_attendance').get().toPromise())
        studentScanQrcodeAttendanceRef.forEach(async att => {
          if (att && att.key) {
            await this.ds.collection('students').doc(student.key).collection('scan_qrcode_attendance').doc(att.key).delete();
          }
        });

        const scan_qrcode_attendanceRef = pushToArray(await this.ds.collection('stores').doc(SCHOOL.key).collection('scan_qrcode_attendance', ref => ref.where('studentKey', '==', student.key)).get().toPromise())
        scan_qrcode_attendanceRef.forEach(async att => {
          if (att && att.key) {
            await this.ds.collection('stores').doc(SCHOOL.key).collection('scan_qrcode_attendance').doc(att.key).delete();
          }
        });

        const studentScanQrcodeAttendanceRef2 = pushToArray(await this.ds.collection('students').doc(student.key).collection('scan_qrcode_attendanceV2').get().toPromise())
        studentScanQrcodeAttendanceRef2.forEach(async att => {
          if (att && att.key) {
            await this.ds.collection('students').doc(student.key).collection('scan_qrcode_attendanceV2').doc(att.key).delete();
          }
        });


        const scan_qrcode_attendanceRef2 = pushToArray(await this.ds.collection('stores').doc(SCHOOL.key).collection('scan_qrcode_attendanceV2', ref => ref.where('studentKey', '==', student.key)).get().toPromise())
        scan_qrcode_attendanceRef2.forEach(async att => {
          if (att && att.key) {
            await this.ds.collection('stores').doc(SCHOOL.key).collection('scan_qrcode_attendanceV2').doc(att.key).delete();
          }
        });

        const timeAttendancesStoreRef = pushToArray(await this.ds.collection('stores').doc(SCHOOL.key).collection('time_attendance', ref => ref.where('studentKey', '==', student.key)).get().toPromise())
        timeAttendancesStoreRef.forEach(async att => {
          if (att && att.key) {
            await this.ds.collection('stores').doc(SCHOOL.key).collection('time_attendance').doc(att.key).delete();
          }
        });

        const timeAttendancesMovementStoreRef = pushToArray(await this.ds.collection('stores').doc(SCHOOL.key).collection('student_attendance_movement', ref => ref.where('studentKey', '==', student.key)).get().toPromise())
        timeAttendancesMovementStoreRef.forEach(async att => {
          if (att && att.key) {
            await this.ds.collection('stores').doc(SCHOOL.key).collection('student_attendance_movement').doc(att.key).delete()
          }
        });

        const timeAttendancesMovementRef = pushToArray(await this.ds.collection('students').doc(student.key).collection('student_attendance_movement').get().toPromise())
        timeAttendancesMovementRef.forEach(async att => {
          if (att && att.key) {
            await this.ds.collection('students').doc(student.key).collection('student_attendance_movement').doc(att.key).delete()
          }
        });

        const schoolAttendanceConfirmLog = pushToArray(await this.ds.schoolFireDocRef().collection("attendance_confirmation_logs").where("scheduleKey", "==", this.selectedSchedule.key).get());
        schoolAttendanceConfirmLog.forEach(async log => {
          if (log && log.key) {
            await this.ds.schoolFireDocRef().collection("attendance_confirmation_logs").doc(log.key).delete()
          }
        });

        const attendanceConfirmLog = pushToArray(await this.ds.collection('employees').doc(this.selectedSchedule.instructorKey).collection("attendance_confirmation_logs").get().toPromise());
        attendanceConfirmLog.forEach(async log => {
          if (log && log.key) {
            await this.ds.collection('employees').doc(this.selectedSchedule.instructorKey).collection('student_attendance_movement').doc(log.key).delete()
          }
        });

        console.log(`${index + 1} - ${student.puc_id} done of ${item.length}.`);
        if (index === item.length - 1) {
          console.log('Finished');
        }

      }
      this.loading = false;
      return;
    } catch (error) {
      console.log(error);
    }
  }


  @action
  async fetchEmloyeeSchedule(yearKey, teacherKey) {
    const docData = await this.ds.getemployeeClassRef(yearKey, teacherKey).get().toPromise();
    const doc = pushToArray(docData)
    const schedule = doc.filter(m => !m.isCourseCriteria)
    return schedule;
  }

  async checkExistsHoliday(dateKey) {
    const docRef = pushToArray(await this.ds.storeRef().collection('public_holiday', ref => ref.where('arrayDateKey', 'array-contains', dateKey)).get().toPromise());
    return docRef
  }

}
